class Button {
    render() {
        var div = document.createElement("div");

        var button = document.createElement('div');
        button.classList.add("button");
        button.innerHTML = this.data.text;
        button.contentEditable = true;
        div.appendChild(button);

        var url = document.createElement("div");
        url.classList.add("invisible");
        url.innerHTML = this.data.url;
        div.appendChild(url);
        this.urlEl = url;

        return div;
    }

    save(el) {
        const button = el.querySelector('.button');
        return Object.assign(this.data, {
            url: this.urlEl.innerHTML,
            text: button.innerHTML
        });
    }

    renderSettings(){

        const wrapper = document.createElement('div');

        var title = document.createElement("h3");
        title.innerHTML = "Button";

        var urlLabel = document.createElement('label');
        urlLabel.innerHTML = "URL";
        var urlInput = document.createElement('input');
        urlInput.setAttribute("placeholder", "URL");
        urlInput.value = this.urlEl.innerHTML;
        var self = this;
        urlInput.addEventListener("change",  (val) => {
            self.urlEl.innerHTML = val.target.value;
        });
        wrapper.appendChild(title);
        wrapper.appendChild(urlLabel);
        wrapper.appendChild(urlInput);

        return wrapper;
      }

    constructor({data, config, api}){
        this.data = {
            url: data.url || "",
            text: data.text || ""
        };
    }

    static get toolbox() {
        return {
          icon: '<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none"/><path d="M3.9 12c0-1.71 1.39-3.1 3.1-3.1h4V7H7c-2.76 0-5 2.24-5 5s2.24 5 5 5h4v-1.9H7c-1.71 0-3.1-1.39-3.1-3.1zM8 13h8v-2H8v2zm9-6h-4v1.9h4c1.71 0 3.1 1.39 3.1 3.1s-1.39 3.1-3.1 3.1h-4V17h4c2.76 0 5-2.24 5-5s-2.24-5-5-5z"/></svg>',
          title: 'Button'
        };
    }
}

module.exports = Button;