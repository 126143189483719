import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { CreateDescriptionDialogComponent } from '../create-description-dialog/create-description-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DescriptionItemDto, DescriptionService } from 'src/app/containers/project/description/description.service';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'app-create-description-widget',
  templateUrl: './create-description-widget.component.html',
  styleUrls: ['./create-description-widget.component.scss']
})
export class CreateDescriptionWidgetComponent implements OnInit {

  @Input() correlationId: string = null;
  @Input() isDialog: boolean = false;
  item: DescriptionItemDto = null;

  constructor(public dialog: MatDialog, private descriptionService: DescriptionService) { }

  ngOnInit() { }

  open(): void {
    const dialogRef = this.dialog.open(CreateDescriptionDialogComponent, {
      width: '800px',
      data: { item: this.item }
    });
  }

  createText(): void {
    this.item = new DescriptionItemDto({type: 'text', value: '', id: this.correlationId});
    this.open();
  }

  createButton(): void {
    this.item = new DescriptionItemDto({type: 'button', value: '', id: this.correlationId});
    this.open();
  }

  uploaded($event: any): void {
    this.descriptionService.createItem(new DescriptionItemDto({type: 'image', value: $event.url, id: this.correlationId }));
  }
}
