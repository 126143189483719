import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Project } from 'src/app/_models/project';
import { ProjectProxyService } from 'src/app/services/project-proxy.service';


@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})

export class AnalyticsComponent implements OnInit {

  selectedProjectId: String = this.activatedRoute.snapshot.paramMap.get('id');
  project: Project;
  


  constructor(
    private activatedRoute: ActivatedRoute,
    private projectService: ProjectProxyService,
    private _location: Location
  ) {}

  ngOnInit() {
    this.projectService.getProjectWithId(this.selectedProjectId).subscribe(data => {
      this.project = data;
    });
  }

  goBack(): void {
    this._location.back();
  }

}