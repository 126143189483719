import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from "@angular/router";

@Component({
  selector: 'app-qrscanner',
  templateUrl: './qrscanner.component.html',
  styleUrls: ['./qrscanner.component.sass']
})
export class QrscannerComponent implements OnInit {

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<QrscannerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

  qrFound($event: String) {
    var id = $event.substring($event.lastIndexOf("/") + 1, $event.length);
    this.router.navigate(['viewer', id]).then( (e) => {
      if (e) {
        this.close();
      } else {
        alert("Couldn't go to project");
      }
    });;
  }

}
