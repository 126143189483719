import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { AnalyticsService } from 'src/app/services/analytics.service';
import {MatRadioModule} from '@angular/material/radio';
import { Project } from 'src/app/_models/project';
import { Voting } from 'src/app/_models/voting';
import { PrivacynoticeComponent } from 'src/app/components/privacynotice/privacynotice.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-voting',
  templateUrl: './voting.component.html',
  styleUrls: ['./voting.component.scss']
})
export class VotingComponent implements OnInit {

  @Input() project: Project;
  @Output() close = new EventEmitter();
  
  currentVoting: Voting = new Voting();
  feedbackForm: boolean = false;
  otherAnswer: boolean = false;
  otherAnswerValue: string = "";

  constructor(
    private analyticsService: AnalyticsService,
    private privacyDialog: MatDialog
  ) { }

  ngOnInit() {
  }

  vote(vote: Number): void {
 
    this.currentVoting.vote = vote;
    this.currentVoting.project = this.project._id;

    if(vote == 0){
      this.feedbackForm = true;
    } else {
      this.close.emit();
      this.analyticsService.sendVote(this.currentVoting).subscribe(res => {
        this.currentVoting = res;
        console.log(this.currentVoting);
      },
      err => {
        console.log(err);
      });
    }
  }

  openPrivacy(): void {
    const settingsDialogRef = this.privacyDialog.open(PrivacynoticeComponent, {
      width: '800px'
    });
  }

  dismiss(): void {
    this.close.emit();
  }

  sendFeedback(feedback: string): void {
    this.close.emit();
    if(this.otherAnswerValue != ""){
      this.currentVoting.feedback = this.otherAnswerValue;
    } else {
      this.currentVoting.feedback = feedback;
    }
    console.log(this.currentVoting);

    this.currentVoting.project = this.project._id;
    this.analyticsService.sendVote(this.currentVoting).subscribe(res => {
      console.log(res);
    },
    err => {
      console.log(err);
    });
  }

}
