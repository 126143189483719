import { Injectable, Inject } from '@angular/core';
import { BaseService } from './base-proxy.service';
import { AppSettings } from '../app-settings';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { Http } from '@angular/http';

@Injectable({
providedIn: 'root'
})
export class PointService extends BaseService {
    constructor(
        @Inject(Http) http: Http, 
        @Inject(AuthenticationService) authenticationService: AuthenticationService
    ) { 
        super(http, authenticationService);
    }

    getContent(id: String): Observable<PointContentResultDto> {
        const url = `${AppSettings.API_ENDPOINT}/magic/plugins/text/${id}`;
        let options = this.getOptions();
        return this.sendRequestWithResponse(url, options, PointContentResultDto.fromJS, new PointContentResultDto());
    }
}

export class PointContentResultDto {
    text: string;  
    constructor(){}

    init(data?: any) {
        if (data) {
            this.text = data;
        }
    }

    static fromJS(data: any): PointContentResultDto {
        let result = new PointContentResultDto();
        result.init(data);
        return result;
    }
}