import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertComponent } from './alert/alert.component';
import { AlertService } from './alert/alert.service';
import { MaterialModule } from '../modules/material.module';
import { CreateDescriptionWidgetComponent } from './description/create-description-widget/create-description-widget.component';
import { CreateDescriptionDialogComponent } from './description/create-description-dialog/create-description-dialog.component';
import { ShareComponent } from './share/share.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UploadFormComponent } from './upload-form/upload-form.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    TranslateModule
  ],
  declarations: [
    AlertComponent,
    CreateDescriptionWidgetComponent,
    CreateDescriptionDialogComponent,
    ShareComponent,
    UploadFormComponent,
  ],
  exports: [
    AlertComponent,
    CreateDescriptionWidgetComponent,
    CreateDescriptionDialogComponent,
    ShareComponent,
    UploadFormComponent
  ],
  providers: [
    {
      provide: AlertService,
      useClass: AlertService
    }
  ],
  entryComponents: [CreateDescriptionDialogComponent, ShareComponent],
})
export class ComponentsModule { }