export class CommonService {
    public static cmToMeters(mt: number): number | null {
        if(!mt) {
            return null;
        }
        
        return Number(mt / 100);
    }

    public static mtToCentimeters(cm?: number): number | null {
        if(!cm) {
            return null;
        }
        
        return cm * 100;
    }

    public static isObject(obj: any): boolean {
        return typeof(obj) == "object";
    }

    public static getId(): string {
        var ALPHABET = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        var ID_LENGTH = 16;
        var rtn = '';
        for (var i = 0; i < ID_LENGTH; i++) {
            rtn += ALPHABET.charAt(Math.floor(Math.random() * ALPHABET.length));
        }
        return rtn;
    }

    public static getAllUrlParams(url) {
        var queryString = url;
        var obj = {};
        if (queryString) {
    
          queryString = queryString.split('#')[0];
          var arr = queryString.split('&');
      
          for (var i = 0; i < arr.length; i++) {
            var a = arr[i].split('=');
            var paramName = a[0];
            var paramValue = typeof (a[1]) === 'undefined' ? true : a[1];
      
            paramName = paramName.toLowerCase();
            if (typeof paramValue === 'string') paramValue = decodeURI(paramValue);
            if (paramName.match(/\[(\d+)?\]$/)) {
              var key = paramName.replace(/\[(\d+)?\]/, '');
              if (!obj[key]) obj[key] = [];
              if (paramName.match(/\[\d+\]$/)) {
                var index = /\[(\d+)\]/.exec(paramName)[1];
                obj[key][index] = paramValue;
              } else {
                obj[key].push(paramValue);
              }
            } else {
              if (!obj[paramName]) {
                obj[paramName] = paramValue;
              } else if (obj[paramName] && typeof obj[paramName] === 'string'){
                obj[paramName] = [obj[paramName]];
                obj[paramName].push(paramValue);
              } else {
                obj[paramName].push(paramValue);
              }
            }
          }
        }
        return obj;
      }
}