import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../../../services/authentication.service';
import { FormGroup } from '@angular/forms';
import { AuthFormService } from '../../../services/auth-form.service';
import { PrivacynoticeComponent } from 'src/app/components/privacynotice/privacynotice.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  
  message = "";
  registerForm: FormGroup;
  loading: boolean = false;
  submitted: boolean = false;
  
  constructor(
    private authenticationService: AuthenticationService,
    private authFormService: AuthFormService,
    private router: Router,
    private privacyDialog: MatDialog
    ) {}

  ngOnInit() {
    this.registerForm = this.authFormService.getForm();
  }

  get controls() {
    return this.registerForm.controls;
  }

  openPrivacy(): void {
    const settingsDialogRef = this.privacyDialog.open(PrivacynoticeComponent, {
      width: '800px'
    });
  }

  register(): void {
    this.submitted = true;
    
    if(!this.registerForm.valid) {
      this.message = this.authFormService.getFormError(this.registerForm);
      return;
    }

    this.loading = true;
    this.authenticationService.register(this.controls.email.value, this.controls.password.value)
    .pipe(first())
    .subscribe(
        data => {
            this.loading = false;
            this.router.navigate(['projects']);
        },
        error => {
          this.loading = false;
          this.message = this.authFormService.getAuthError(error);
        });
  }
}