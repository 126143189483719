import { Component, OnInit } from '@angular/core';
import {trigger,state,style,animate,transition} from '@angular/animations';
import { ProjectStateService } from '../../services/project-state.service';
import { Project } from 'src/app/_models/project';

@Component({
  selector: 'app-project-wrapper',
  templateUrl: './project-wrapper.component.html',
  styleUrls: ['./project-wrapper.component.scss'],
  animations: [
    trigger('openClose', [
      state('open', style({
        left: '-100%',
      })),
      state('closed', style({
        left: '0%',
      })),
      transition('open => closed', [
        animate('0.3s cubic-bezier(0.0, 0.0, 0.2, 1)')
      ]),
      transition('closed => open', [
        animate('0.3s cubic-bezier(0.0, 0.0, 0.2, 1)')
      ]),
    ]),
  ]
})
export class ProjectWrapperComponent implements OnInit {
  
  isProjectOpen = true;

  constructor(
    private projectState: ProjectStateService,
  ) { }
  
  selectedProject: Project; 

  ngOnInit() {
    this.projectState.projectSelected.subscribe(project => {
     
      if(project == undefined){
        this.isProjectOpen = false;
      } else {
        this.isProjectOpen = true;
      }
      
      this.selectedProject = project;
    });
  }

  showProjects(): void {
    this.projectState.select(undefined);
  }

}
