import { GeoPose } from './geopose';

export class MagicPoint {
    id?: String;
    _id?: String;
    project: String;
    step : number;
    title: String;
    objectTrackingInformation?: {
		anchorType?: string,
		src?: string,
		width?: number
    };
    contentProviderUrl: String;
    content?: string;
    type?: string;
    color?: string;
    matrix : [{type : Number}];
    geoPose?: GeoPose;
    environment?: string; // URL to screenshot, later to 3D scan data
    environmentMatrix? : [{type : Number}]; // the transform of the photo relative to the magicpoint  
}
