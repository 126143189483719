import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'sphira';
  constructor(private translate: TranslateService){
    if(localStorage.getItem("language") != "en" && localStorage.getItem("language") != "de"){
      localStorage.setItem("language", "en");
    }
    translate.setDefaultLang(localStorage.getItem("language"));
  }
}
