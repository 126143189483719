import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { SwiperComponent, SwiperDirective, SwiperConfigInterface, SwiperConfig } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-vertical-swiper-wrapper',
  templateUrl: './vertical-swiper-wrapper.component.html',
  styleUrls: ['./vertical-swiper-wrapper.component.scss']
})
export class VerticalSwiperWrapperComponent implements OnInit {

  constructor() { }
  config: SwiperConfig = new SwiperConfig({
    slidesPerView: 1,
    direction: "vertical",
    spaceBetween: 0,
    observer: true,
    observeParents: true,
    autoHeight: true 
  });

  @ViewChild(SwiperComponent, {static:false}) componentRef?: SwiperComponent;
  @ViewChild(SwiperDirective, {static:false}) directiveRef?: SwiperDirective;

  @Output() swipeStateEvent = new EventEmitter();
  @Input() isInitiallyUp:boolean = false;

  ngOnInit() {
    if(this.isInitiallyUp){
      this.directiveRef.setIndex(1);
    }
  }

  public onIndexChange(index: number): void {     // when swiped or changed from outside
    var state = false;
    if(index == 1) {state = true;}
    this.swipeStateEvent.emit(state);
  }

}
