import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Project } from '../../../_models/project';
import { ShareComponent } from 'src/app/components/share/share.component';

import { ProjectProxyService } from '../../../services/project-proxy.service';
import { ProjectService } from '../project/project.service';

@Component({
  selector: 'app-project-more-sheet',
  templateUrl: './project-more-sheet.component.html',
  styleUrls: ['./project-more-sheet.component.scss']
})
export class ProjectMoreSheetComponent implements OnInit {

  @Input() project: Project;
  
  constructor(
    private dialog: MatDialog,
    private bottomSheetRef: MatBottomSheetRef<ProjectMoreSheetComponent>,
    private projectProxyService: ProjectProxyService,
    private projectService: ProjectService,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) {
    if(this.data) {
      this.project = this.data.project;
    } 
  }

  ngOnInit() { }

  rename(): void {
    var title = prompt("Title", this.project.title);
    if(!title) {
      return;
    }

    this.project.title = title;
    this.projectService.update(this.project);
    this.dismiss();
  }

  delete(): void {
    this.projectService.delete(this.project._id);
    this.dismiss();
  }

  inspect(): void {
    window.open("http://localhost:8080&project="+this.project._id, '_blank');
  }

  share(): void {
    const dialogRef = this.dialog.open(ShareComponent, {
      width: '800px',
      data: { project: this.project }
    });
  }

  dismiss(): void {
    if(this.bottomSheetRef && this.bottomSheetRef.dismiss) {
      this.bottomSheetRef.dismiss();
    }
  }
}