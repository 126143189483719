import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { HttpClient,HttpParams } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DescriptionService, DescriptionItemDto } from 'src/app/containers/project/description/description.service';
import { CommonService } from 'src/app/services/common.service';


@Component({
  selector: 'app-create-description-dialog',
  templateUrl: './create-description-dialog.component.html',
  styleUrls: ['./create-description-dialog.component.scss']
})
export class CreateDescriptionDialogComponent implements OnInit {
  
  @ViewChild("textarea", {static:false}) textareaEl: ElementRef;
  item: DescriptionItemDto = new DescriptionItemDto({type: 'text', value: ''});

  buttonTitle: string;
  buttonUrl: string;
  embed: boolean;
  embedValue: string;

  constructor(
    private descriptionService: DescriptionService,
    public dialogRef: MatDialogRef<CreateDescriptionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    if(this.data && this.data.item) {
      this.item.id = this.data.item.id;
      this.item.value = this.data.item.value;
      this.item.type = this.data.item.type;

      if(this.item.type == "button"){
        const values = CommonService.getAllUrlParams(this.item.value);
        this.buttonTitle = values['title'];
        this.buttonUrl = values['url'];
        this.embedValue = values['embed'];
        if(this.embedValue=="true"){this.embed = true;} else {this.embed = false;}
      }
    }
  }

  save(): void {
    
    var embedValue = "false";
    if(this.embed){embedValue="true";}

    if(this.item.type == "button"){
      const params = new HttpParams()
      .set('title', this.buttonTitle)
      .set('embed', embedValue)
      .set('url', encodeURI(this.buttonUrl));
      this.item.value = params.toString();
    }

    if(this.data.index != undefined) {
      this.descriptionService.updateItem({item: this.item, index: this.data.index});
    }
    else {
      this.descriptionService.createItem(this.item);
    }
    this.dialogRef.close();
  }

  cancel(): void {
    this.dialogRef.close();
  }

  ngAfterViewInit() {
    this.textareaEl.nativeElement.focus();
  }

}
