import { Component, OnInit, EventEmitter, Input, SimpleChanges, ViewEncapsulation, ChangeDetectorRef, ElementRef, ViewChild, Renderer2} from '@angular/core';
import { MagicPoint } from '../../../_models/magicpoint';
import { MagicService } from 'src/app/services/magic.service';
import { WalkthroughService } from 'src/app/services/walkthrough.service';
import { EditorService } from 'src/app/services/editor.service';
import { ProjectService } from '../project/project.service';
import { ProjectProxyService } from 'src/app/services/project-proxy.service';
const ParagraphView = require('./../../../plugins/paragraph/view.js');
const ChecklistView = require('./../../../plugins/checklist/view.js');
const ButtonView = require('./../../../plugins/button/view.js');
const TextinputView = require('./../../../plugins/textinput/view.js');

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EditorComponent implements OnInit {

  @Input() point: MagicPoint = null;
  @Input() walkthroughViewer: boolean = false;
  @Input() edit: boolean = false;
  private viewerEl: ElementRef;

  @ViewChild('editorContainer', {static: true}) editorContainer: ElementRef;
  @ViewChild('viewerEl', {static:false}) set content(content: ElementRef) {
     this.viewerEl = content;
  }

  uniqueId: string = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

  viewerHtml: string = "";
  initiating: boolean = false;
  resetTimer: any;
  resetAfterInit: boolean = false;
  viewer: boolean = true;
  get walkthroughRunning(){return this.walkthroughSerivce.walkthroughRunning;}

  constructor(
    private magicService: MagicService,
    private changeDetector : ChangeDetectorRef,
    private walkthroughSerivce: WalkthroughService,
    public editorService: EditorService,
    private projectService: ProjectProxyService
  ) { }

    ngOnInit() {

    }

  ngAfterViewInit() {
     
      var self = this;
      setTimeout(function(){
        if(self.edit){
          self.initEditor();
          self.viewer = false;
        } else {
          self.renderViewer();
        }
      }, 400);
  
  }

  renderViewer(): void {
    this.viewer = true;
    this.changeDetector.detectChanges();
    var json = JSON.parse(this.point.content).blocks;
    var first = this.viewerEl.nativeElement.firstElementChild; 
    while (first) { 
        first.remove(); 
        first = this.viewerEl.nativeElement.firstElementChild; 
    } 
    for(var i = 0; i < json.length; i++) {
      var className = this.capitalizeFLetter(json[i].type);
      var plugin = eval(`new ${className}View()`);
      var domElement = plugin.view(json[i].data);
      console.log(json[i].data);
      console.log(this.viewerEl.nativeElement);
      this.viewerEl.nativeElement.appendChild(domElement);
    }
  }

  initEditor(): void {
    this.editorService.initEditor(this.editorContainer.nativeElement, this.point);
  }

  addBlock(type: string): void {
    this.editorService.editor.blocks.insert(type, {}, {}, this.editorService.editor.blocks.getBlocksCount(), true);
    this.editorService.editor.caret.setToLastBlock();
    this.editorService.editor.toolbar.open(true);
    var curBlock = this.editorService.editor.blocks.getBlockByIndex(this.editorService.editor.blocks.getCurrentBlockIndex());
    curBlock.classList.add('ce-block--focused');
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'edit': {
            
            if(this.edit && !this.walkthroughViewer){
              this.initEditor();
              this.viewer = false;
            } else {
              this.viewer = true;              
              this.renderViewer();
            }

          }
        }
      }
    }
  }

  capitalizeFLetter(input): string { 
    return input.charAt(0).toUpperCase() + input.slice(1); 
  } 

}
