import { Component, OnInit } from '@angular/core';

import { Router } from "@angular/router";
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../../../services/authentication.service';
import { FormGroup } from '@angular/forms';
import { AuthFormService } from '../../../services/auth-form.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  message = '';

  loading = false;
  submitted = false;
  returnUrl: string;

  loginForm: FormGroup;

  constructor(
    private authenticationService: AuthenticationService,
    private authFormService: AuthFormService,
    private router: Router
    ) { }

  ngOnInit() {
    this.initForm();
  }

  initForm(): void {
    this.loginForm = this.authFormService.getForm();
  }

  get controls() {
    return this.loginForm.controls;
  }

  login(): void {
    this.submitted = true;
    if (!this.loginForm.valid) {
      this.message = this.authFormService.getFormError(this.loginForm);
      return;
    }

    this.loading = true;
    this.authenticationService.login(this.controls.email.value, this.controls.password.value)
    .pipe(first())
    .subscribe(
      result => {
        this.loading = false;
        this.router.navigate(['projects']);
      },
      error => {
        this.loading = false;
        this.message = this.authFormService.getAuthError(error);
      });
  }
}