import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ChangePasswordService } from './change-password.service';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../../../services/authentication.service';
import { AlertTypes } from '../../../components/alert/alert.component';
import { AppConsts } from '../../../_models/app-consts';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  changePasswordForm: FormGroup;
  message: string = '';
  submitted: boolean = false;
  loading: boolean = false;
  type: string = 'error';

  constructor(private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private service: ChangePasswordService) { }

  ngOnInit() {
    this.changePasswordForm = this.service.getForm();
  }

  get controls() {
    return this.changePasswordForm.controls;
  }

  changePassword(): void {
    this.message = '';
    this.type = AlertTypes.default;
    this.submitted = true;
    const isMatch = this.controls.newPassword.value == this.controls.confirmPassword.value;
    if (!this.changePasswordForm.valid || !isMatch) {
      this.type = AlertTypes.error;
      this.message = this.service.getFormError(this.changePasswordForm);
      return;
    }

    this.authenticationService.changePassword(this.controls.newPassword.value)
    .pipe(first())
    .subscribe(
      result => {
        this.submitted = false;
        this.type = AlertTypes.default;
        this.message = AppConsts.messages.password.changed;
        this.changePasswordForm.reset();
      },
      error => { });
  }
}