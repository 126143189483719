import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets, ChartAnimationOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import * as moment from 'moment';
import { unitOfTime } from 'moment'
import { AnalyticsPoint } from 'src/app/_models/analyticspoint';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-timetable',
  templateUrl: './timetable.component.html',
  styleUrls: ['./timetable.component.scss']
})
export class TimetableComponent implements OnInit, OnChanges {

  @Input() tableData: any[][];  // Is an array of arrays of data entries
  @Input() labelData: string[];

  datesBack: number = 7;
  filterBy: String = "week";

  public barChartOptions: ChartOptions = {
    responsive: true,
    animation: true as ChartAnimationOptions,
    legend: {
      display: false
    },
  
    tooltips: {enabled:false},
    // We use these empty structures as placeholders for dynamic theming.
    scales: { 
      xAxes: [{
        display:false,
      }], 
      yAxes: [{
        gridLines: {
          drawBorder:false
        }
      }], 
      scaleLabel: {
        fontFamily: 'Gilroy',
        padding: 20
      }
    },
  };
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'line';
  public barChartLegend = false;
  public chartColors: Array<any> = [
    { 
      backgroundColor: 'rgba(114,232,119,0)',
      borderColor: ['#72E877']
    },
    { 
      backgroundColor: 'rgba(252,84,104,0)',
      borderColor: ['#FC5468']
    }
]

  public barChartData: ChartDataSets[] = [{data:[],label:""}];
  
  constructor() { }

  ngOnInit() {
    this.updateTable();
  }

  ngOnChanges($event: SimpleChanges): void {
    this.updateTable();
  }

  public onValChange(val: string) {
    this.filterBy = val;
    this.updateTable();
  }

  updateTable(): void {

    this.barChartData = [];
    this.tableData.forEach((item, index) => {
      if(index == 0){
        this.barChartData[0] = {data: [], label: this.labelData[index], pointRadius: 0};
      } else {
        this.barChartData.push({data: [], label: this.labelData[index], pointRadius: 2});
      }
    });

    var today = moment();
    var period: string;
    var filter; // current looped date (days, months)

    this.barChartLabels = [];
    if(this.filterBy == "week"){
      this.datesBack = 7;
      period = "day";
    } else if (this.filterBy == "month"){
      this.datesBack = 31;
      period = "day";
    } else if (this.filterBy == "year") {
      this.datesBack = 12;
      period = "month";
    } else if (this.filterBy == "day") {
      this.datesBack = 24;
      period = "hour";
    }

    for(var i=this.datesBack; i >= 0; i--){
      // Current time period
      filter = moment().subtract(i, period+'s' as unitOfTime.DurationConstructor);   
      
      // Setup labels
      if(this.filterBy == "week"){
        this.barChartLabels.push(filter.format('dddd'));
      } else if (this.filterBy == "month"){
        this.barChartLabels.push(filter.format('MMM Do'));
      } else if (this.filterBy == "year") {
        this.barChartLabels.push(filter.format('MMMM'));
      } else if (this.filterBy == "day") {
        this.barChartLabels.push(filter.format('HH[h]'));
      }


      this.tableData.forEach((item, index) => {
      
        // Filter votes by current time period
        var dataTotalPrimary = this.tableData[index].filter(res => {
          if(moment(res.createdAt).isSame(filter, period as unitOfTime.DurationConstructor)){return res}
        });
 
        this.barChartData[index].data[this.datesBack - i] = dataTotalPrimary.length;
        this.barChartData[index].label = this.labelData[index];

      });
      

    }


  }

}
