import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../_models/user';
import { AppSettings } from '../app-settings';
import { NativeService } from './native.service';
//(import { ProjectStateService } from './project-state.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(
    private router:Router,
    private http: HttpClient,
    private nativeService: NativeService,
  ) { 
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    if(this.currentUserSubject.value){
      this.nativeService.loggedIn(this.currentUserSubject.value.token); // send token to unity
      return this.currentUserSubject.value;
    }
    var emptyUser: User = {
     _id: "",
     token: "",
     email: "",
     name: "",
     profilePicture: ""
    }

    return emptyUser;
  }
  
  login(email: string, password: string) {
    var payload = {
      "user": {
        "email":email, 
        "password": password
      }
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    }
    console.log(JSON.stringify(payload));

    return this.http.post<any>(AppSettings.API_ENDPOINT+'/users/login', JSON.stringify(payload), httpOptions)
        .pipe(map(data=> {
            var user = data.user;
            // login successful if there's a jwt token in the response
            if (user && user.token) {
                console.log(user);
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.nativeService.loggedIn(user.token); // send token to unity
                this.currentUserSubject.next(user);
            }

            return user;
        }));
  }

  register(email: string, password: string) {
  
    var payload = {
      "user": {
        "email":email, 
        "password": password
      }
    }

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    }

    console.log(JSON.stringify(payload));

    return this.http.post<any>(AppSettings.API_ENDPOINT+'/users/register', JSON.stringify(payload), httpOptions)
        .pipe(map(data=> {
            var user = data.user;
            // login successful if there's a jwt token in the response
            if (user && user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.nativeService.loggedIn(user.token); // send token to unity
                this.currentUserSubject.next(user);
            }

            return user;
        }));
  }

  changeUsername(name: string){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Token ' + this.currentUserValue.token
      })
    };
    var data = {"name": name};
    console.log(data);
    return this.http.post<any>(AppSettings.API_ENDPOINT+'/users/changename', JSON.stringify(data), httpOptions)
        .pipe(map(data=> {
          localStorage.setItem('currentUser', JSON.stringify(data));
        }, err => {
          console.log(err);
        }));
  }

  changeProfilepicture(url: string){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Token ' + this.currentUserValue.token
      })
    };

    return this.http.post<any>(AppSettings.API_ENDPOINT+'/users/changeprofile', url, httpOptions)
        .pipe(map(data=> {
          console.log(data)
        }));
  }

  deleteAcount(){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Token ' + this.currentUserValue.token
      })
    };
    
    var id = this.currentUserValue._id;

    return this.http.delete<any>(AppSettings.API_ENDPOINT+'/users/'+id, httpOptions)
        .pipe(map(data=> {
          console.log(data)
          this.logout();
        }));
  }

  getUserForId(id: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Token ' + this.currentUserValue.token
      })
    };
    return this.http.get<any>(AppSettings.API_ENDPOINT+'/users/get/'+id, httpOptions)
        .pipe(map(user => {
                return user;
        }));
  }

  changePassword(password: string) {
  
    var payload = {
      "user": { "password": password }
    };

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Token ' + this.currentUserValue.token
      })
    };

    return this.http.post<any>(AppSettings.API_ENDPOINT+'/users/changepw', JSON.stringify(payload), httpOptions)
        .pipe(map(data=> {
          return {};
            var user = data.user;
            if (user && user.token) {
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.currentUserSubject.next(user);
            }

            return user;
        }));
  }


  logout() {
      // remove user from local storage to log user out
      localStorage.removeItem('currentUser');
      this.currentUserSubject.next(null);
      //this.router.navigate(['auth']);
      location.reload();
  }
}