import { Component, OnInit, Input, ViewChild, SimpleChanges } from '@angular/core';
import { WalkthroughService } from 'src/app/services/walkthrough.service';
import { Walkthrough } from 'src/app/_models/walkthrough';
import {MatSort} from '@angular/material/sort';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import * as moment from 'moment';
import { unitOfTime } from 'moment'
import { ProjectProxyService } from 'src/app/services/project-proxy.service';
import { Project } from 'src/app/_models/project';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-reportlist',
  templateUrl: './reportlist.component.html',
  styleUrls: ['./reportlist.component.scss']
})
export class ReportlistComponent implements OnInit {

  @ViewChild(MatTable, {static: true}) table: MatTable<any>;
  //@Input() forProjects: string[] = [];
  walkthroughs: Walkthrough[];
  dataSource: WalkthroughData[] = [];
  project: Project = new Project;
  @Input() projects: Project[] = [];
  displayedColumns: string[] = ['project', 'date', 'user', 'checklist'];
  @Input() singleProject: Boolean = false;

  constructor(
    private walkthroughService: WalkthroughService,
    private projectService: ProjectProxyService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit() {
  }

  initTable() {
    this.walkthroughs = [];
    this.dataSource = [];
    this.project = new Project;
    this.displayedColumns = ['project', 'date', 'user', 'checklist'];
    
    if(this.singleProject){this.displayedColumns.shift();}  // don't display project if single project vieww

    if(this.projects.length == 0){  // if no projects passed, query all of them

      this.projectService.getProjects().subscribe(projects => {
        this.projects = projects.slice().reverse();
        this.fillTableData();
      });

    } else {
      this.fillTableData();
    }
  }

  fillTableData() {

    this.projects.forEach(project => {
      this.walkthroughService.getAllWalkthroughsForProject(project._id).subscribe(data => {
        console.log("report data", data);
        this.walkthroughs = data.reverse();
        
        this.walkthroughs.forEach(walkthrough => {
            var d = new WalkthroughData();
            d.project = project.title;
            d.date = moment(walkthrough.start).format('DD.MM.YY');

            var ms = moment(walkthrough.start,"DD/MM/YYYY HH:mm:ss").diff(moment(walkthrough.end,"DD/MM/YYYY HH:mm:ss"));
            var dur = moment.duration(moment(walkthrough.end).diff(moment(walkthrough.start))).asMilliseconds();
            d.duration = moment.utc(dur).format("HH:mm:ss");

            this.authenticationService.getUserForId(walkthrough.user).subscribe(data => {
              d.user = data.user.name;
              console.log(data.user);
              this.table.renderRows();
            });

            var numberOfCheckmarks = 0;
            var numberOfWarnings = 0;
            var numberOfErrors = 0;
          
            walkthrough.snapshot.forEach(mp => {
              var checklistBlocks = mp.filledContent.blocks.filter(block => block.type === 'checklist');
              checklistBlocks.forEach(el => {
                const checkmarks = el.data.items.filter(item => item.type === "checkmark" && item.checked === true);
                numberOfCheckmarks += checkmarks.length;
                const warnings = el.data.items.filter(item => item.type === "warning" && item.checked === true);
                numberOfWarnings += warnings.length;
                const errors = el.data.items.filter(item => item.type === "error" && item.checked === true);
                numberOfErrors += errors.length;
              });
            });

            d.user = "...";
            d._id = walkthrough._id;
            d.numberOfCheckmarks = numberOfCheckmarks;
            d.numberOfWarnings = numberOfWarnings;
            d.numberOfErrors = numberOfErrors;
            this.dataSource.push(d);
        });
      
        this.table.renderRows();
    
      });
    })
    

  }

  ngOnChanges() {
    this.initTable();
  }
  
  
}

class WalkthroughData {
  _id: string;
  project: string;
  date: string;
  user: string;
  duration: string;
  numberOfCheckmarks: number;
  numberOfWarnings: number;
  numberOfErrors: number;
  checklistColor: string;
}
