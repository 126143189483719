import { Injectable, HostListener } from '@angular/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { MagicPoint } from '../_models/magicpoint';

@Injectable({
  providedIn: 'root'
})
export class MagicPointService {

  pointDeleted: Subject<any> = new Subject<any>();
  pointSelected: BehaviorSubject<MagicPoint> = new BehaviorSubject<MagicPoint>(null);
  swapChanged: Subject<string> = new Subject<string>();

  isCreationAvailable: boolean = true;
  isActiveSwipeDemo: boolean = false;
  
  constructor() { }

  getSelected(): Observable<MagicPoint> {
    return this.pointSelected.asObservable();
  }

  select(point: MagicPoint): void {
    return this.pointSelected.next(point);
  }
  
}
