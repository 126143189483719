class ChecklistView {

    view(data) {
      this.data = data;
        var elements = [];
        this.wrapper = document.createElement("div");

        if (data.items.length) {
            data.items.forEach(item => {
              const newItem = this.createChecklistItem(item);
              elements.push(newItem);
              this.wrapper.appendChild(newItem);
            });
          } else {
            const newItem = this.createChecklistItem();
              elements.push(newItem);
              this.wrapper.appendChild(newItem);
          }
          
        this.wrapper.addEventListener('click', (event) => {
          console.log(event);
            this.toggleCheckbox(event);
        });
    
        var exportEl = document.createElement("div");
        exportEl.appendChild(this.wrapper);
        return this.wrapper;
    }

    _make(tagName, classNames = null, attributes = {}) {
        const el = document.createElement(tagName);
    
        if (Array.isArray(classNames)) {
          el.classList.add(...classNames);
        } else if (classNames) {
          el.classList.add(classNames);
        }
    
        for (let attrName in attributes) {
          el[attrName] = attributes[attrName];
        }
    
        return el;
      }
    
    toggleCheckbox(event) {
        if(!this.data.settings.multiselection) {
          var lastEl = this.wrapper.querySelector('.'+this.CSS.itemChecked);
          if(lastEl != undefined){
            lastEl.classList.remove(this.CSS.itemChecked);
          }
        }
    
        const checkListItem = event.target.closest(".cdx-checklist__item");
        console.log(checkListItem);
        const checkbox = checkListItem.querySelector(".cdx-checklist__item-checkbox");
    
      //  if (checkbox.contains(event.target)) {
          checkListItem.classList.toggle(this.CSS.itemChecked);
       // }
      }

    createChecklistItem(item = {}) {
        const checkListItem = this._make('div', this.CSS.item);
        checkListItem.setAttribute("type", item.type);

        const checkbox = this._make('span', this.CSS.checkbox);
    
        const textField = this._make('div', this.CSS.textField, {
          innerHTML: item.text ? item.text : ''
        });
    
        if (item.checked) {
          checkListItem.classList.add(this.CSS.itemChecked);
        }
    
        checkListItem.appendChild(checkbox);
        checkListItem.appendChild(textField);

        return checkListItem;
      }

      get CSS() {
        return {
          baseBlock: '',
          wrapper: 'cdx-checklist',
          item: 'cdx-checklist__item',
          itemChecked: 'cdx-checklist__item--checked',
          checkbox: 'cdx-checklist__item-checkbox',
          textField: 'cdx-checklist__item-text'
        };
      }
    
}

module.exports = ChecklistView;