class ParagraphView {

    view(data) {
        var exportEl = document.createElement("div");
        exportEl.innerHTML = '<p>'+data.text+'</p>';
        return exportEl;
    }

}

module.exports = ParagraphView;