import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import {MatListModule} from '@angular/material/list';
import {MatCardModule} from '@angular/material/card';
import {MatSlideToggleModule, MatSlideToggle} from '@angular/material/slide-toggle';
import { ProjectProxyService } from '../../services/project-proxy.service';
import { Project } from '../../_models/project';


@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss']
})
export class ShareComponent implements OnInit {
  
  constructor(
    public dialogRef: MatDialogRef<ShareComponent>,
    private projectService: ProjectProxyService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  
    project: Project;
    url: string = "disabled";
    isPublic: boolean = false;

    contributors = [];
    addCollaboratorsForm: FormGroup;

  ngOnInit() {
    this.project = this.data.project;
    this.isPublic = this.project.isPublic;
    this.url = "https://app.sphira.co/viewer/" + this.project._id;

    this.addCollaboratorsForm = this.formBuilder.group({
      email: new FormControl('')
    });

    this.getCollaborators();

  }

  close() {
    this.dialogRef.close();
  }

  isPublicToggle($event) {
    this.project.isPublic = this.isPublic;
    this.projectService.update(this.project).subscribe(res => {
      console.log(res);
    });
  }

  copyUrlToClipboard () {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.url;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  getCollaborators() {
    this.projectService.getCollaborators(this.project._id).subscribe(res => {
      console.log(res);
      this.contributors = res;
    });
  }

  addCollaborator() {
    console.log("Add collaborator");
    this.projectService.addCollaborator(this.addCollaboratorsForm.controls.email.value, this.project._id).subscribe(res => {
        this.getCollaborators();
    }, error => {
      alert("User does not exist");
    });
  }

  removeCollaborator(id: string) {
    this.project.collaboratingUsers = this.project.collaboratingUsers.filter(item => item != id);
    this.projectService.update(this.project).subscribe(res => {
      this.getCollaborators();
    });
  }


}
