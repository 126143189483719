import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map, timeInterval } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { AppSettings } from '../app-settings';
import { Voting } from '../_models/voting';
import { MagicPoint } from '../_models/magicpoint';
import { AnalyticsPoint } from '../_models/analyticspoint';
import BrowserInteractionTime from 'browser-interaction-time';
import { MagicPointService } from './magic-point.service';


@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  token: string
  userId: string
  currentProject: string
  currentMagicPoint: MagicPoint
  projectSession: string
  magicPointSession: string
  filterBy: String = "day"


  timerRunning: boolean = false
  browserInactive: boolean = false;
  httpOptions: {}

  projectTimer: BrowserInteractionTime
  magicTimer: BrowserInteractionTime

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private magicPointService: MagicPointService
  ) {
    this.token = this.authenticationService.currentUserValue.token || "";
    this.userId = this.authenticationService.currentUserValue._id || "";

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Token '+this.token
      })
    }

    var self = this;
    this.magicPointService.getSelected().subscribe((point) => {
     this.currentMagicPoint = point;
     setTimeout(function(){     // escape some weird async thing, function would not be called otherwise
        self.trackMagicPoint();
     },0);
    });

    const browserInactiveCallback = () => this.browserInactive = true
    const browserActiveCallback = () => this.browserInactive = false

    // Project View Timer
    this.projectTimer = new BrowserInteractionTime({
      timeIntervalEllapsedCallbacks: [],
      absoluteTimeEllapsedCallbacks: [],
      browserTabInactiveCallbacks: [browserInactiveCallback],
      browserTabActiveCallbacks: [browserActiveCallback],
      idleTimeoutMs: 3000,
      checkCallbacksIntervalMs: 1000
    })
    const projectCallback = {
      multiplier: time => time,
      timeInMilliseconds: 1000,
      callback: () => {
        if(!this.timerRunning || this.browserInactive){return}
        this.sendProjectDataPoint();
      }
    }
    this.projectTimer.addTimeIntervalEllapsedCallback(projectCallback)


    // MagicPoint timer
    this.magicTimer = new BrowserInteractionTime({
      timeIntervalEllapsedCallbacks: [],
      absoluteTimeEllapsedCallbacks: [],
      browserTabInactiveCallbacks: [],
      browserTabActiveCallbacks: [],
      idleTimeoutMs: 3000,
      checkCallbacksIntervalMs: 1000
    })
    const magicPointCallback = {
      multiplier: time => time,
      timeInMilliseconds: 1000,
      callback: () => {
        if(!this.timerRunning || this.browserInactive){return}
        this.sendMagicPointDataPoint();
      }
    }
    this.magicTimer.addTimeIntervalEllapsedCallback(magicPointCallback)

  }


  // Votings
  getVotesForProject(project: String): Observable<Voting[]> {
    return this.http.get<any>(AppSettings.API_ENDPOINT+'/voting/forproject/'+project, this.httpOptions)
    .pipe(map(res => res))
  }

  sendVote(voting: Voting): Observable<any> {
    //console.log("send vote");
    return this.http.post<any>(AppSettings.API_ENDPOINT+'/voting/vote', JSON.stringify(voting), this.httpOptions)
    .pipe(map(res => res))
  }


  // Analytics
  getAnalyticsPointsForProject(project: String): Observable<AnalyticsPoint[]> {
    return this.http.get<any>(AppSettings.API_ENDPOINT+'/analytics/forproject/'+project, this.httpOptions)
    .pipe(map(res => res))
  }

  trackProject(id: string): void {
    this.projectSession = Math.random().toString(36).substr(2, 9);
    this.currentProject = id;
    this.projectTimer.startTimer();
    this.timerRunning = true;
  }

  trackMagicPoint(): void {
    //console.log("track magic point", this.currentMagicPoint._id);
    this.magicPointSession = Math.random().toString(36).substr(2, 9);
    this.magicTimer.reset();
    this.magicTimer.startTimer();
  }

  stopTracking(): void {
    this.sendProjectDataPoint();

    this.magicTimer.stopTimer();
    this.magicTimer.reset();
    this.projectTimer.stopTimer();
    this.projectTimer.reset();
    this.timerRunning = false;
  }

  sendMagicPointDataPoint(): void {
    this.sendDataPoint("magicpoint", this.currentMagicPoint._id as string, this.magicTimer.getTimeInMilliseconds(), this.magicPointSession).subscribe(res => {
        //done
    }, err => {
      console.log(err);
    });
  }

  sendProjectDataPoint(): void {
    this.sendDataPoint("project", this.currentProject, this.projectTimer.getTimeInMilliseconds(), this.projectSession).subscribe(res => {
      //done
    }, err => {
      console.log(err);
    });
  }

  sendDataPoint(dataType: string, dataId: string, timespan: number, session: string): Observable<any> {
    //console.log("send data point", dataType, dataId, timespan, session);
    return this.http.post<any>(AppSettings.API_ENDPOINT+'/analytics/save', JSON.stringify({
      project: this.currentProject,
      session: session,
      user: this.userId,
      dataType: dataType,
      dataId: dataId,
      timespan: timespan
    }), this.httpOptions)
    .pipe(map(res => res))
  }

  

}