import { Injectable } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AppConsts } from '../../../_models/app-consts';


@Injectable({
    providedIn: 'root'
})
export class ChangePasswordService {

    constructor(private formBuilder: FormBuilder) { }

    getForm(): FormGroup {
        return this.formBuilder.group({
            newPassword: ['', [Validators.required, Validators.minLength(6)]],
            confirmPassword: ['', [Validators.required, Validators.minLength(6)]]
        });
    }

    getAuthError(error: any): string {
        let message = '';

        if (error.status == AppConsts.httpErrors.badRequest) {
            message = AppConsts.errors.invalidEmailOrPassword;
        }
        
        return message;
    }

    getFormError(form: FormGroup): string {
        let message = '';
        let passwordErrors = form.controls.newPassword.errors;
        let confirmErrors = form.controls.confirmPassword.errors;
        
        message = this.appendError(passwordErrors && passwordErrors.required, AppConsts.errors.password.required, message);
        message += this.appendError(passwordErrors && passwordErrors.minlength, AppConsts.errors.password.minlength, message);

        if (message.length) {
            return message;
        }
        
        const isNotMatch = form.controls.newPassword.value != form.controls.confirmPassword.value;
        message = this.appendError(isNotMatch, AppConsts.errors.password.notMatch, message);

        return message;
    }

    private appendError(condition: boolean, error: string, message: string): string {
        if (!condition) {
            return "";
        }

        return message ? `\n${error}` : error;
    }
}