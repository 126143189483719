import { Component, OnInit, Input, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Project } from '../../_models/project';
import { ActivatedRoute } from '@angular/router';
import { Multimarker } from '../../_models/multimarker';
import { ProjectProxyService } from '../../services/project-proxy.service';
import { Asset } from 'src/app/_models/asset';
import { ProjectStateService } from 'src/app/services/project-state.service';

@Component({
  selector: 'app-inspector',
  templateUrl: './inspector.component.html',
  styleUrls: ['./inspector.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InspectorComponent implements OnInit {

  private project: Project;
  private asset: Asset;

  constructor(
    private activatedRoute: ActivatedRoute,
    private projectStateService: ProjectStateService,
    private projectService: ProjectProxyService
  ) { }

  initScene(): void {
    
    var scene = document.querySelector('a-scene');
    var assets = document.querySelector('a-assets');

    var el : any = document.createElement("a-entity");
    if(!el.object3D){el.object3D = {};}
    
    el.setAttribute("gltf-model", this.asset.src);


    scene.appendChild(el);

    console.log(el.object3D);

    var THREE : any = THREE || {}; 
    //const quaternion = new THREE.Quaternion(m[3], m[4], -m[5], -m[6]);
    //el.object3D.applyQuaternion(quaternion);
    //el.object3D.rotateX( Math.PI / -2 );
    //el.object3D.rotateZ( Math.PI / 2 );

  }

  uploaded($event): void {
    var asset = new Asset();
    asset.src = $event;
    this.project.asset = asset;
    this.projectService.update(this.project).subscribe(project => {
      this.project = project;
    });
  }

  ngOnInit() {
    this.projectStateService.getSelected().subscribe(project => {
      this.asset = project.asset;
      this.project = project;
    });
  }

  ngOnChanges($event: SimpleChanges): void {
    if(!$event.asset) {
      return;
    }

    this.initScene();

  }

}
