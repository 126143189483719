import { Injectable } from '@angular/core';
import { Http, Headers, Response, ResponseContentType } from '@angular/http';
import { AuthenticationService } from './authentication.service';
import { Observable, of, throwError } from 'rxjs';
import { AppConsts } from '../_models/app-consts';
import { mergeMap, catchError } from 'rxjs/operators';

declare let toastr: any;

@Injectable({
    providedIn: 'root'
})
export abstract class BaseService {
    protected jsonParseReviver: (key: string, value: any) => any = undefined;

    constructor(private http: Http, private authenticationService: AuthenticationService) {
        
    }

    get token(): String {
        
        return this.authenticationService.currentUserValue.token;
    }

    protected addTokenToHeader(options: any) {
        options.headers.append('Authorization', `Token ${this.token}`);
    }

    protected sendRequestWithResponse<T>(url: any, options: any, fromJs: any, obj: T): Observable<T> {
        this.addTokenToHeader(options);
        return this.http.request(url, options).pipe(mergeMap((response) => {
            return this.processAction<T>(response, fromJs, obj, this.jsonParseReviver);
        })).pipe(catchError((response: any) => {
            return <Observable<T>><any>throwError(response);
        }))
    }

    protected processAction<T>(response: Response, fromJS: (t: any) => T, emptyObject: T, jsonParseReviver: any): Observable<T> {
        const status = response.status;
        let headers: any = response.headers ? response.headers.toJSON() : {};

        if (status === AppConsts.httpStatusCodes.ok) {
            const responseText = response.text();
            let result200: any = null;
            var resultData200: any = null;

            try {
                resultData200 = responseText === "" ? null : JSON.parse(responseText, jsonParseReviver); 
            } catch (e) {
                resultData200 = responseText;
            }

            result200 = resultData200 ? fromJS(resultData200) : emptyObject;
            return of(result200);
        } else if (status !== AppConsts.httpStatusCodes.ok && status !== AppConsts.httpStatusCodes.noContent) {
            const responseText = response.text();
            return this.throwException("An unexpected server error occurred.", status, responseText, headers);
        }
        return of<T>(<any>null);
    }

    protected throwException(message: string, status: number, response: string, headers: { [key: string]: any; }, result?: any): Observable<any> {
        if (result !== null && result !== undefined) {
            
            return Observable.throw(result);
        }
        return Observable.throw(new SwaggerException(message, status, response, headers, null));
    }

    protected getPostOptions(content: any): any {
        return {
            body: content,
            method: "post",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
    }

    protected getOptions(): any {
        return {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accept": "application/json"
            })
        };
    }

    protected getPutOptions(content: any): OptionsDto {
        return new OptionsDto("put", content, new Headers({"Content-Type": "application/json"}));
    }

    protected getDeleteOptions(): any {
        return {
            method: "delete",
            headers: new Headers({
                "Content-Type": "application/json"
            })
        };
    }

    protected getFileOptions(): any {
        return {
            method: "get",
            headers: new Headers({
                "response-type": "arraybuffer"
            })
        };
    }
}

export class SwaggerException extends Error {
    message: string;
    status: number;
    response: string;
    headers: { [key: string]: any; };
    result: any;

    constructor(message: string, status: number, response: string, headers: { [key: string]: any; }, result: any) {
        super();

        this.message = message;
        this.status = status;
        this.response = response;
        this.headers = headers;
        this.result = result;
    }

    protected isSwaggerException = true;

    static isSwaggerException(obj: any): obj is SwaggerException {
        return obj.isSwaggerException === true;
    }
}

export class OptionsDto {
    method: string;
    body: any;
    headers: Headers;

    constructor(method: string, body: any, headers: Headers) {
        this.method = method;
        this.body = body;
        this.headers = headers;
    }
}