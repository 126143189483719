import { Injectable } from '@angular/core';
import { AppSettings } from '../../app-settings';
import { AppConsts } from '../../_models/app-consts';
import { MagicPoint } from '../../_models/magicpoint';
import { CommonService } from '../../services/common.service';

@Injectable({
  providedIn: 'root'
})
export class ViewerService {

  constructor() { }
  lastPoint:MagicPoint = new MagicPoint();
  timer: ReturnType<typeof setTimeout>;

  handle(self: any, detail: any): void {
    const message = detail.msg;
    const payload = detail.payload;
    self.nativeService.debug("handle message: "+message);
    switch(message) {
      case AppConsts.magicActions.pointAdded: {
        console.log(payload);
        this.addUpdatePoint(self, payload);
      } break;
      case AppConsts.magicActions.pointMoved: {
        this.movePoint(self, detail.payload);
      } break;
      case AppConsts.magicActions.pointSelected: {
        let point = self.magicPoints.find(magicpoint => magicpoint._id === payload);
        self.magicPointService.select(point);
      } break;
      case AppConsts.magicActions.markerDetected: {
        if(payload != ""){
          self.showToast("Marker detected: "+ payload);
          self.saveSphiraMarkAfterMarkerDetected(payload);
        }
      } break;
      case AppConsts.magicActions.qrDetected: {
        self.qrDetected(detail);
      } break;
      case AppConsts.magicActions.spatialAnchorSaved: {
        this.spatialAnchorSaved(self, detail);
      } break;
      case AppConsts.magicActions.spatialAnchorScanProgress: {
        self.updateScanProgress(parseFloat(payload));
      } break;
      case AppConsts.magicActions.applicationFocused: {
        
      } break;
      case AppConsts.magicActions.upateAnchors: {
        this.updateAnchors(self, detail);
      } break;
      case "newAnchorFound": {
        self.showToast("new anchor added");
      } break;
      case AppConsts.magicActions.pointEnvironmentChanged: {
        console.log("ViewerService: Update Environment event received");
        self.nativeService.debug("ViewerService: Update Environment event received");
        this.updateEnvironment(self, detail.payload);
      }
    }
  }
  updateAnchors(self: any, detail: any): void {
    self.project.objectTrackingInformation = {
      anchorType: "universal",
      src: detail.payload,
      width: 1
    };
    self.nativeService.debug("web: update Anchors"+ detail.payload);
    
    self.projectService.update(self.project).subscribe(project => {
      self.project = project;
      self.nativeService.debug("web: anchors updated"+ detail.payload);
    });
  }

  updateEnvironment(self: any, payload: any): void {
    var pl = JSON.parse(payload);
    self.nativeService.debug("ViewerService:updateEnvironment: update env for point "+pl.id);
    var magicpoint : MagicPoint = self.magicPoints.find(mp => <MagicPoint>mp._id == pl.id);
    magicpoint.environment = pl.environment;
    self.nativeService.debug("ViewerService:updateEnvironment: found magicpoint to update: "+ magicpoint.title);
    self.magicService.saveMagic(magicpoint).subscribe(magicpoint => {
      console.log("received saved magicpoint", magicpoint);
      self.nativeService.debug("ViewerService:updateEnvironment: received saved magicpoint");
    });
  }

  spatialAnchorSaved(self: any, detail: any): void {
    self.project.objectTrackingInformation = {
      anchorType: "spatial",
      src: detail.payload,
      width: 1
    };
    self.nativeService.debug("web: spatial saved "+ detail.payload);
    
    self.projectService.update(self.project).subscribe(project => {
      self.project = project;
      self.waitingForMarker = false;
      self.specifyMarker = false;
      //self.nativeService.initProject(self.project);
    });
  }

  addUpdatePoint(self, payload): void {
    self.nativeService.debug("addupdatepoint");
    console.log("addupdatepoint");
    let point = self.magicPointService.pointSelected.getValue();
    if(point && !point.matrix.length) {
      //this.updatePoint(self, payload, point);
      console.log("no matrix yet, call movepoint to add matrix");
      this.movePoint(self, payload);
    } else {
      console.log("this is a new magicpoint");
      this.addPoint(self, payload);
    } 
  }

  updatePoint(self, payload, point): void {
    var pl = JSON.parse(payload);
    point.matrix = pl.transform;
    if(pl.objectTrackingInformationType != null){
      point.objectTrackingInformation.anchorType = pl.objectTrackingInformationType;
      point.objectTrackingInformation.src = pl.objectTrackingInformationSrc;
    }
    point.project = self.selectedProject;
    self.magicService.saveMagic(point).subscribe(result => {
      self.magicPoints[result.step - 1] = result;
      self.magicPoints = self.magicPoints.slice();
      self.magicPointService.select(result);
    });
  }

  addPoint(self, payload): void {
    let data = !CommonService.isObject(payload) 
    ? JSON.parse(payload) 
    : payload;
    var template = self.project.template || "";
    console.log("magic point added", data);
    let newIndex = self.magicPoints.length + 1;
    var newMagicPoint: MagicPoint = {
      project: self.selectedProject,
      step: newIndex,
      title: data.title,
      contentProviderUrl: AppSettings.API_ENDPOINT + "/magic/text/",
      content: template,
      matrix: data.transform,
      environment: data.environment
    };

    clearTimeout(this.timer);     // Prevent double call with timer, ugly

    this.timer = setTimeout(()=>{ 
      console.log("timeout ended");
      self.magicService.saveMagic(newMagicPoint).subscribe(magicpoint => {
        console.log("received saved magicpoint", magicpoint);
        self.nativeService.debug("viewer: received saved magicpoint");
        self.magicPoints.push(magicpoint);
        self.magicPoints = self.magicPoints.slice();
        self.magicPointService.select(magicpoint);
      });
    },100);
   
  }

  movePoint(self: any, payload: any): void {
    var data = JSON.parse(payload);
    console.log(data);
    self.nativeService.debug("magicpoint moved");
    console.log(self.magicPoints);
    var mp: MagicPoint = self.magicPoints.find(e => e._id === data.id);
    console.log(mp);
    var newMatrix = !mp.matrix.length
    mp.matrix = data.transform;
    
    self.magicService.saveMagic(mp).subscribe(magicpoint => {
        console.log("magicpoint moved", magicpoint);
        mp = magicpoint;
        if(newMatrix) { self.getMagicPoints(); console.log("updated magicpoint") }   // if no matrix before (placemode triggered because created with non-AR)
        self.nativeService.debug("saved magicpoint after moved");
    })
  }
}
