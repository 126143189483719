import { Component, OnInit, Input, Output, Inject, EventEmitter, ChangeDetectionStrategy, OnDestroy, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Project } from '../../../_models/project';
import { ProjectProxyService } from '../../../services/project-proxy.service';
import { NativeService } from '../../../services/native.service';
import { CommonService } from '../../../services/common.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-project-settings',
  templateUrl: './project-settings.component.html',
  styleUrls: ['./project-settings.component.scss']
})
export class ProjectSettingsComponent implements OnInit {

  @Input() scanProgress: Number = 0;
  @Input() project: Project;
  @Output() isValid = new EventEmitter<Project>();
  @Output() demoMode = new EventEmitter();

  // Param from URL
  //projectId: String = this.activatedRoute.snapshot.paramMap.get('id')
  selectedProject: Project;
  selectedFileName: string = "No file selected"
  isFormValid: Boolean = false
  fileSelected: Boolean = false
  fileUploaded: Boolean = false
  loading = false;
  uploadForm: FormGroup;
  sphiraMarkForm: FormGroup;
  roomScanState: Number = 0;
  labsFeatures: Boolean = false;

  sphiraMarkId: string = "";

  constructor(
    private projectService: ProjectProxyService,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private nativeService: NativeService,
    private dialogRef: MatDialogRef<ProjectSettingsComponent>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    if(localStorage.getItem('labsFeatures') == "true"){
      this.labsFeatures = true;
    } else {
      this.labsFeatures = false;
    }
  }

  ngOnInit() {
    this.project = this.data.project;
    this.uploadForm = this.formBuilder.group({
      file: String,
      imagewidth: [this.cmImgWidth, [Validators.required]]
    });
    this.sphiraMarkId = this.project.objectTrackingInformation.src;
  }

  close() {
    this.dialogRef.close();
  }

  // When file has been selected
  onFileSelect(event) {
    if (event.target.files.length > 0) {
      this.fileSelected = true
      const file = event.target.files[0];
      this.uploadForm.get('file').setValue(file);
      
      this.loading = true;
      const formData = new FormData();
      formData.append('file', this.uploadForm.get('file').value);
      this.projectService.uploadFile(formData).subscribe(res => {
        this.loading = false;
        this.fileUploaded = true;
        this.project.objectTrackingInformation = {
          anchorType: "image",
          src: res.url,
          width: CommonService.cmToMeters(this.uploadForm.controls.imagewidth.value)
        };
        console.log(this.project.objectTrackingInformation);
        this.updateProject(); 
        this.validateForm();
      }, err => {
      
      })
    }
  }

  // When width has been entered
  onSubmit() {
    this.project.objectTrackingInformation = {
        anchorType: "image",
        src: this.project.objectTrackingInformation ? this.project.objectTrackingInformation.src : '',
        width: CommonService.cmToMeters(this.uploadForm.controls.imagewidth.value)
      };

      this.updateProject();
  }

  validateForm() {
    if(this.fileUploaded && !this.uploadForm.controls.imagewidth.errors) {
      // TODO: Fix after adding store
      this.isValid.emit(this.project);
    }
  }

  getProject(): void {
    this.projectService.getProjectWithId(this.project._id).subscribe(project => {
      this.project = project;
      this.uploadForm.controls.imagewidth.setValue(CommonService.mtToCentimeters(this.project.objectTrackingInformation.width));
    })
  }

  updateProject(): void {
    this.projectService.update(this.project).subscribe(project => {
      this.project = project;
      this.nativeService.initProject(this.project);
      this.validateForm();
    })
  }

  onSelect(project: Project): void {
    this.selectedProject = project
   // this.selectedFileName = this.selectedProject.objectTrackingInformation.src
  }

  startSpatialAnchorSession(): void {
    this.roomScanState = 1;
    this.nativeService.startSpatialAnchorSession();
  }

  startUniversal(): void {
    this.nativeService.debug("start universal");
    this.project.objectTrackingInformation = {
      anchorType: "universal",
      src: "",
      width: 1
    };
    this.projectService.update(this.project).subscribe(project => {
      this.project = project;
      this.nativeService.debug("project updated to be universal");
      this.nativeService.initProject(this.project);
    })
  }

  saveRoomAnchor(): void {
    this.nativeService.createSpatialAnchor();
    this.roomScanState = 3;
  }

  saveSphiraMark(): void {
    this.project.objectTrackingInformation = {
      anchorType: "sphiramarker",
      src: this.sphiraMarkId,
      width: 0.105
    };

    console.log(this.project.objectTrackingInformation);

    this.projectService.saveSphiraMark(this.sphiraMarkId, this.project._id).subscribe((data) => {
      console.log(data);
    },
    (error) => {
      console.log(error);
      if (error.status == 200){
        this.isValid.emit(this.project);    // weirdly emits error
      }
    })

    this.updateProject();
  }

  skip(): void {
    this.demoMode.emit();
  }

  setLanguage(lang: string){
    this.translate.use(lang);
    this.project.language = lang;
    this.projectService.update(this.project).subscribe(project => {
      this.project = project;
    });
  }

  setNotificationsEnabled($event): void {
    this.project.notificationsEnabled = $event.checked;
    this.updateProject();
  }


  setType(type: string){
    console.log(type);
    this.project.type = type;
    this.projectService.update(this.project).subscribe(project => {
      this.project = project;
    });
  }

  get cmImgWidth(): number | null {
    return (this.project && this.project.objectTrackingInformation) 
      ? CommonService.mtToCentimeters(this.project.objectTrackingInformation.width)
      : null;
  }

  ngOnChanges(changes: SimpleChanges) {
    
      for (let propName in changes) {
        if (propName === 'scanProgress') {
          if(this.data.scanProgress > 100 && this.roomScanState < 2) {
            this.roomScanState = 2;
          }
        }
        if (propName === 'project'){
          if(this.project.objectTrackingInformation.anchorType == "sphiramark"){
           this.sphiraMarkId = this.project.objectTrackingInformation.src;
          }
        }
      }
  }
}