import { Component, OnInit, Inject } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { AppConsts } from '../../../../_models/app-consts';

@Component({
  selector: 'app-description-text-menu',
  templateUrl: './description-text-menu.component.html',
  styleUrls: ['./description-text-menu.component.sass']
})
export class DescriptionTextMenuComponent implements OnInit {

  constructor(
    private bottomSheetRef: MatBottomSheetRef<DescriptionTextMenuComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) {  }

  ngOnInit() {
  }

  edit(): void {
    this.bottomSheetRef.dismiss(AppConsts.menuState.edit);
  }

  delete(): void {
    this.bottomSheetRef.dismiss(AppConsts.menuState.delete);
  }

}
