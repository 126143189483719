import { Component, OnInit, Inject } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import {MatSlideToggleModule, MatSlideToggle} from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';
import { ChangePasswordComponent } from './change-password/change-password.component';
import {TranslateService} from '@ngx-translate/core'

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  labsFeatures: boolean = false;
  email: string = "";
  name: string ="";
  language: string = "en";

  constructor(
    private dialogRef: MatDialogRef<SettingsComponent>,
    private authenticationService: AuthenticationService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    if(localStorage.getItem('labsFeatures') == "true"){
      this.labsFeatures = true;
    } else {
      this.labsFeatures = false;
    }
    this.language = localStorage.getItem("language");
    console.log(this.authenticationService.currentUserValue);
    this.email = this.authenticationService.currentUserValue.email;
    this.name = this.authenticationService.currentUserValue.name;
  }

  ngOnInit() {

   }

  logout(): void {
    this.authenticationService.logout();
  }

  deleteAccount() {
    var r = confirm("Do you really want to delete your account?");
    if(r){
      this.authenticationService.deleteAcount();
    }
  }

  changeUsername(){
    console.log(this.name);
    this.authenticationService.changeUsername(this.name).subscribe(user => {
      console.log(user);
    });
  }

  close() {
    this.dialogRef.close();
  }

  setLanguage(lang: string){
    this.translate.use(lang);
    localStorage.setItem('language', lang);
  }

  toggleLabsFeatures(state: boolean){
    this.labsFeatures = state;
    if(state){
      localStorage.setItem('labsFeatures', "true");
    } else {
      localStorage.setItem('labsFeatures', "false");
    }
    
  }
}