import { Injectable, EventEmitter } from '@angular/core';
import { SwiperConfig } from 'ngx-swiper-wrapper';
import { Subject, Observable } from 'rxjs';
import { AppConsts } from '../../../_models/app-consts';

@Injectable({
  providedIn: 'root'
})
export class DescriptionService {

  createdItem: Subject<DescriptionItemDto> = new Subject<DescriptionItemDto>();
  updatedItem: Subject<any> = new Subject<any>();
  isSingle: boolean = false;
  scrollEvents: Subject<any> = new Subject<any>();
  checkScroll: boolean = false;

  constructor() { }

  getUpdatedItems(): Observable<any> {
    return this.updatedItem.asObservable();
  }

  updateItem(item: any): void {
    this.updatedItem.next(item);
  }

  getCreatedItems(): Observable<DescriptionItemDto> {
    return this.createdItem.asObservable();
  }

  createItem(item: DescriptionItemDto): void {
    this.createdItem.next(item);
  }

  getSwiperConfig(direction: 'horizontal' | 'vertical'): SwiperConfig {
    let config = new SwiperConfig({
      direction: direction,
      spaceBetween: 15,
      watchSlidesProgress: true
    });
    return config;
  }

  toggle(config: SwiperConfig): SwiperConfig {
    if(config.slidesPerView === "auto") {
      config.slidesPerView = 1;
    } else {
      config.slidesPerView = "auto";
    }

    return config;
  }

  activateSwiper(config: SwiperConfig, state: boolean): SwiperConfig {
    config.touchRatio = state ? 1 : 0;
    return config;
  }

  parseDescription(content: any, id: string): any {
    if(!content) {
      return [];
    }

    content.items.forEach(item => item.id = id);
    return content.items;
  }

  formatDescription(items: Array<DescriptionItemDto>): string {
      const content = JSON.stringify(items);
      return `{"items":${content}}`;
  }

  initScroll(): Observable<any> {
    document.getElementById("descScroll").addEventListener("scroll", ($event : any) => {
      this.scrollEvents.next($event);
    });
    return this.scrollEvents.asObservable();
  }

  destroyScroll(): void {
    document.getElementById("descScroll").removeEventListener("scroll", null);
  }

  getFromNodes(nodes: any, zone: number): string {
    // NOTE: Skips first element
    for(let count = nodes.length - 1; count > 0; count--) {
        if(zone > nodes[count].getBoundingClientRect().top) {
          return nodes[count].id;
        }
    }
    return "";
  }
}

export class DescriptionItemDto {
  type: string;
  value: string;
  id: string;

  constructor(data: any) {
    this.init(data);
  }

  init(data?: any) {
      if (data) {
          this.type = data.type;
          this.value = data.value;
          this.id = data.id;
      }
  }
}