import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { AlertService } from './alert.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit, OnChanges {

  @Input() message: string | null = null;
  @Input() type: string | null = null;
  private cssClass: string | null = null;

  constructor(private alertService: AlertService) { }

  ngOnInit() { }

  ngOnChanges() {
    this.cssClass = this.alertService.getClass(this.type);
  }

  clear(): void {
    this.message = '';
  }
}

export const AlertTypes = {
  default: "default",
  error: "error"
}