import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { NativeService } from './native.service';
import { AppSettings } from '../app-settings';
import { Walkthrough } from '../_models/walkthrough';
import { MagicSnapshot } from '../_models/magicsnapshot';
import { MagicPoint } from '../_models/magicpoint';
import { ProjectStateService, ViewState } from './project-state.service';
import { ProjectService } from '../containers/project/project/project.service';
import { ProjectProxyService } from './project-proxy.service';
import { EditorService } from './editor.service';
import { DialogSubmitWalkthroughComponent } from '../dialogs/dialog-submit-walkthrough/dialog-submit-walkthrough.component';
import {MatDialog} from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class WalkthroughService {

  token: String
  userId: String
  httpOptions: {}

  walkthroughRunning: boolean = false;
  currentWalkthrough: Walkthrough;
  currentProjectId: string;

  walkthroughState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private projectStateService: ProjectStateService,
    private projectService: ProjectProxyService,
    private dialog: MatDialog,
    ) { 
      this.token = this.authenticationService.currentUserValue.token || "";
      this.userId = this.authenticationService.currentUserValue._id || "";

      this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Token '+this.token
      })}
  }

  startWalkthrough(projectId: string): void {
    this.walkthroughRunning = true;
    this.projectStateService.setViewState(ViewState.viewer);
    this.currentWalkthrough = new Walkthrough();
    this.currentWalkthrough.project = projectId;
    this.saveCurrentWalkthrough().subscribe(walkthrough => {
      this.currentWalkthrough = walkthrough;
      console.log(this.currentWalkthrough);
    });
    this.walkthroughState.next(true);
  }

  resumeWalkthrough(walkthrough: Walkthrough){
    this.walkthroughRunning = true;
    this.projectStateService.setViewState(ViewState.viewer);
    this.currentWalkthrough = walkthrough
    this.currentWalkthrough.project = walkthrough.project;
    this.walkthroughState.next(true);
  }

  stopWalkthrough(notificationsEnabled: boolean): void {

    const dialogRef = this.dialog.open(DialogSubmitWalkthroughComponent, {
      width: '300px',
      data: {sendNotification:false, notificationsEnabled: notificationsEnabled}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined){
        this.walkthroughRunning = false;
        this.walkthroughState.next(false);
        this.currentWalkthrough.end = new Date();
        this.projectStateService.setViewState(ViewState.static);
        this.saveCurrentWalkthrough().subscribe(walkthrough => {
          this.currentWalkthrough = walkthrough;
          if(result.sendNotification){
            this.sendReportNotification(walkthrough._id).subscribe(res=>{
              console.log(res);
            });
          }
        });
      }
    });
    
  }

  updateMagicSnapshot(id: String, content: any) {
    var newSnap = this.currentWalkthrough.snapshot.find(snap => snap.magicpoint === id);
    console.log(newSnap);
    if(newSnap == undefined){
      console.log("create new magicsnapshot");
      newSnap = new MagicSnapshot();
      newSnap.magicpoint = id;
      newSnap.filledContent = content;
      this.currentWalkthrough.snapshot.push(newSnap);
    } else {
      console.log("upadte magicsnapshot");
      newSnap.filledContent = content;
    }
    this.saveCurrentWalkthrough().subscribe(walkthrough => {
      this.currentWalkthrough = walkthrough;
      console.log(this.currentWalkthrough);
    });
  }

  getWalkthroughById(id: String): Observable<Walkthrough> {     // Id of Walkthrough
    return this.http.get<any>(AppSettings.API_ENDPOINT+'/walkthroughs/get/'+id, this.httpOptions)
     .pipe(map(res => res))
  }

  getAllWalkthroughsForProject(id: String): Observable<Walkthrough[]> {   // Project ID
    return this.http.get<any>(AppSettings.API_ENDPOINT+'/walkthroughs/list/'+id, this.httpOptions)
     .pipe(map(res => res))
  }

  saveCurrentWalkthrough(): Observable<Walkthrough> {
    return this.http.post<any>(AppSettings.API_ENDPOINT+'/walkthroughs/save', JSON.stringify(this.currentWalkthrough), this.httpOptions)
    .pipe(map(res=>res))
  }

  sendReportNotification(reportId: string): Observable<any> {
    return this.http.get<any>(AppSettings.API_ENDPOINT+'/walkthroughs/notify/'+reportId, this.httpOptions)
    .pipe(map(res=>res))
  }

}
