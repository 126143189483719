import { Injectable } from '@angular/core';

@Injectable()
export class AlertService {

    classes: any = {
        default: 'alert-default',
        error: 'alert-error',
        warning: 'alert-warning'
    };

    constructor() { }

    getClass(type: string): string {
        return !!this.classes[type] ? this.classes[type] : this.classes.default; 
    }

}