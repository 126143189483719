import { Component, OnInit, Input } from '@angular/core';
import { Project } from 'src/app/_models/project';
import { ShareComponent } from 'src/app/components/share/share.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-share-widget',
  templateUrl: './share-widget.component.html',
  styleUrls: ['./share-widget.component.scss']
})
export class ShareWidgetComponent implements OnInit {

  @Input() project: Project

  constructor(              
    private dialog: MatDialog
    ) { }

  ngOnInit() {
  }

  share(): void {
    const dialogRef = this.dialog.open(ShareComponent, {
      width: '800px',
      data: { project: this.project }
    });
  }

}
