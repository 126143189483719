
export class AppConsts {
    static readonly errors = {
        email: {
            required: "Email is required",
            invalid: "Invalid email address",
            notvalidated: "Email address not confirmed. Check your inbox."
        },

        password: {
            required: "Password is required",
            minlength: "Password must be at least 6 characters",
            notMatch: "Confirm password does not match"
        },
        
        invalidEmailOrPassword: "Invalid email or password"
    };

    static messages = {
        password: {
            changed: "Password was successfully changed"
        }
    }

    static readonly httpErrors = {
        badRequest: 400
    }

    static readonly magicActions = {
        pointAdded: "magicPointAdded",
        pointMoved: "magicPointMoved",
        pointSelected: "magicPointSelected",
        markerDetected: "markerDetected",
        qrDetected: "qrDetected",
        spatialAnchorSaved: "spatialAnchorSaved",
        spatialAnchorScanProgress: "spatialAnchorScanProgress",
        applicationFocused: "applicationFocused",
        upateAnchors: "updateAnchors",
        pointEnvironmentChanged: "magicPointEnvironmentChanged",
    }

    static readonly descState = {
        down: "down",
        up: "up",
        auto: "auto",
        expandNotAR: "expandNotAR",
        demo: 'demo'
    }

    static readonly menuState = {
        edit: 'edit',
        delete: 'delete'
    }

    static readonly httpStatusCodes = {
        ok: 200,
        noContent: 204,
        timeOut: 408,
        unauthorized: 401
    };

    static readonly tabletWidth = 1024;
    static readonly mobileWidth = 768;
}