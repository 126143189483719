import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms'; // <-- NgModel lives here
import { environment } from '../environments/environment';

import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { AuthGuard } from './guard/auth-guard.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { RegisterComponent } from './containers/auth/register/register.component';
import { LoginComponent } from './containers/auth/login/login.component';
import { ProjectsComponent } from './containers/project/projects/projects.component';
import { ViewerComponent } from './containers/viewer/viewer.component';
import { ProjectSettingsComponent } from './containers/project/project-settings/project-settings.component';
import { ProjectMoreSheetComponent } from './containers/project/project-more-sheet/project-more-sheet.component';
import { DescriptionComponent } from './containers/project/description/description.component';
import { MagicpointlistComponent } from './containers/magicpoint/magicpointlist/magicpointlist.component';
import { MaterialModule } from './modules/material.module';
import { ComponentsModule } from './components/components.module';
import { HttpModule } from '@angular/http';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { AframePipeModule } from 'angular-aframe-pipe';
import * as Hammer from 'hammerjs';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { DescriptionItemComponent } from './containers/project/description-item/description-item.component';
import { ProjectComponent } from './containers/project/project/project.component';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatRadioModule} from '@angular/material/radio';
import {MatDialogModule} from '@angular/material/dialog';
import { DescriptionTextComponent } from './containers/project/description-item/create-desc-text/description-text.component';
import { DescriptionTextMenuComponent } from './containers/project/description-item/description-text-menu/description-text-menu.component';
import { CreateDescriptionWidgetComponent } from './components/description/create-description-widget/create-description-widget.component';
import { CreateDescriptionDialogComponent } from './components/description/create-description-dialog/create-description-dialog.component';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { LoginRegisterComponent } from './containers/auth/login-register/login-register.component';
import { QrscannerComponent } from './components/qrscanner/qrscanner.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { InspectorComponent } from './components/inspector/inspector.component';
import { VerticalSwiperWrapperComponent } from './components/vertical-swiper-wrapper/vertical-swiper-wrapper.component';
import { SettingsComponent } from './containers/settings/settings.component';
import { ChangePasswordComponent } from './containers/settings/change-password/change-password.component';
import { PrivacynoticeComponent } from './components/privacynotice/privacynotice.component';
import { VotingComponent } from './components/voting/voting.component';
import { AnalyticsComponent } from './containers/analytics/analytics.component';
import { ChartsModule } from 'ng2-charts';
import { AccountManagerComponent } from './containers/account-manager/account-manager.component';
import { AnalyticsService } from './services/analytics.service';
import { VotingDashComponent } from './components/analytics/voting-dash/voting-dash.component';
import { ProjectDashComponent } from './components/analytics/project-dash/project-dash.component';
import { TimetableComponent } from './components/analytics/timetable/timetable.component';
// import ngx-translate and the http loader
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient} from '@angular/common/http';
import { EnvironmentComponent } from './components/environment/environment.component';
import { EditorComponent } from './containers/project/editor/editor.component';
import { DeferLoadModule } from '@trademe/ng-defer-load';
import { MagicpointComponent } from './components/environment/magicpoint/magicpoint.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { DataComponent } from './containers/data/data.component';
import { ProjectWrapperComponent } from './containers/project-wrapper/project-wrapper.component';
import { DashboardComponent } from './containers/dashboard/dashboard.component';
import { ReportsComponent } from './containers/reports/reports.component';
import { ReportlistComponent } from './containers/reports/reportlist/reportlist.component';
import { DialogProjectNameComponent } from './dialogs/dialog-project-name/dialog-project-name.component';
import { DialogConfirmDeleteComponent } from './dialogs/dialog-confirm-delete/dialog-confirm-delete.component';
import { ProjectDetailComponent } from './containers/project/project-detail/project-detail.component';

// Widgets
import { ReportsWidgetComponent } from './components/widgets/reports-widget/reports-widget.component';
import { ShareWidgetComponent } from './components/widgets/share-widget/share-widget.component';
import { TagsWidgetComponent } from './components/widgets/tags-widget/tags-widget.component';
import { StatsWidgetComponent } from './components/widgets/stats-widget/stats-widget.component';
import { StartWalkthroughWidgetComponent } from './components/widgets/start-walkthrough-widget/start-walkthrough-widget.component';
import { ShortcutsWidgetComponent } from './components/widgets/shortcuts-widget/shortcuts-widget.component';
import { DialogSubmitWalkthroughComponent } from './dialogs/dialog-submit-walkthrough/dialog-submit-walkthrough.component';
import { ProfileSettingsComponent } from './containers/profile-settings/profile-settings.component';

const appRoutes: Routes = [];

@NgModule({
  declarations: [
    AppComponent,
    ProjectsComponent,
    ViewerComponent,
    LoginComponent,
    RegisterComponent,
    ProjectSettingsComponent,
    ProjectMoreSheetComponent,
    DescriptionComponent,
    DescriptionItemComponent,
    MagicpointlistComponent,
    DescriptionTextComponent,
    ProjectComponent,
    DescriptionTextMenuComponent,
    LoginRegisterComponent,
    QrscannerComponent,
    InspectorComponent,
    VerticalSwiperWrapperComponent,
    ChangePasswordComponent,
    SettingsComponent,
    PrivacynoticeComponent,
    VotingComponent,
    AnalyticsComponent,
    AccountManagerComponent,
    VotingDashComponent,
    ProjectDashComponent,
    TimetableComponent,
    EnvironmentComponent,
    EditorComponent,
    MagicpointComponent,
    DataComponent,
    ProjectWrapperComponent,
    DashboardComponent,
    ReportsComponent,
    ReportlistComponent,
    DialogProjectNameComponent,
    DialogConfirmDeleteComponent,
    ProjectDetailComponent,
    ReportsWidgetComponent,
    ShareWidgetComponent,
    TagsWidgetComponent,
    StatsWidgetComponent,
    StartWalkthroughWidgetComponent,
    ShortcutsWidgetComponent,
    DialogSubmitWalkthroughComponent,
    ProfileSettingsComponent
  ],
  imports: [
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: true } // <-- debugging purposes only
    ),
    HttpModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgxPageScrollCoreModule.forRoot({ duration: 200 }),
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    MatFormFieldModule,
    DragDropModule,
    MatRadioModule,
    SwiperModule,
    ComponentsModule,
    AframePipeModule,
    ZXingScannerModule,
    Ng2ImgMaxModule,
    ChartsModule,
    DeferLoadModule,
    HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
    }),
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  exports: [RouterModule, ChartsModule],
  providers: [
    AnalyticsService,
    AuthGuard,
    // TODO: Move to separate module
    { provide: MatBottomSheetRef, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_BOTTOM_SHEET_DATA, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: {} }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [
    ProjectMoreSheetComponent, 
    DescriptionTextMenuComponent, 
    CreateDescriptionWidgetComponent, 
    CreateDescriptionDialogComponent, 
    QrscannerComponent,
    SettingsComponent,
    PrivacynoticeComponent,
    DialogProjectNameComponent,
    DialogConfirmDeleteComponent,
    DialogSubmitWalkthroughComponent
  ]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}