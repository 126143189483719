import { Component, OnInit, Inject } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import {MatSlideToggleModule, MatSlideToggle} from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';
import {TranslateService} from '@ngx-translate/core'

@Component({
  selector: 'app-profile-settings',
  templateUrl: './profile-settings.component.html',
  styleUrls: ['./profile-settings.component.sass']
})
export class ProfileSettingsComponent implements OnInit {
  
  email: string = "";
  name: string ="";

  constructor(
    private dialogRef: MatDialogRef<ProfileSettingsComponent>,
    private authenticationService: AuthenticationService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.name = this.authenticationService.currentUserValue.name;
  }

 logout(): void {
   this.authenticationService.logout();
 }

 deleteAccount() {
   var r = confirm("Do you really want to delete your account?");
   if(r){
     this.authenticationService.deleteAcount();
   }
 }

 changeUsername(){
   this.authenticationService.changeUsername(this.name).subscribe(user => {
     this.logout();
   });
 }

 close() {
   this.dialogRef.close();
 }

}