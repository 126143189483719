import { Component, OnInit, Input } from '@angular/core';
import { Project } from 'src/app/_models/project';

@Component({
  selector: 'app-shortcuts-widget',
  templateUrl: './shortcuts-widget.component.html',
  styleUrls: ['./shortcuts-widget.component.scss']
})
export class ShortcutsWidgetComponent implements OnInit {

  @Input() project: Project

  constructor() { }

  ngOnInit() {
  }

}
