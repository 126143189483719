import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-project-name',
  templateUrl: './dialog-project-name.component.html',
  styleUrls: ['./dialog-project-name.component.scss']
})
export class DialogProjectNameComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogProjectNameComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit():void{}

}


export interface DialogData {
  title: string;
}
