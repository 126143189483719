class TextinputView {
    view(data) {
        var exportEl = document.createElement("textarea");
        exportEl.value = data.text;
        exportEl.placeholder = data.placeholder;
        exportEl.rows = data.rows;
        return exportEl;
    }
}

module.exports = TextinputView;