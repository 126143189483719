import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter, OnChanges, ChangeDetectorRef, ViewChild } from '@angular/core';

import { MagicPoint } from '../../../_models/magicpoint';
import { MagicService } from '../../../services/magic.service';
import { NativeService } from '../../../services/native.service';
import { AppConsts } from '../../../_models/app-consts';
import { PointService } from '../../../services/point-proxy.service';
import { MagicPointService } from 'src/app/services/magic-point.service';
import { DescriptionItemDto, DescriptionService } from '../description/description.service';
import { swipeDescription } from './animations/swipe-description';
import { CommonService } from 'src/app/services/common.service';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { MatMenu } from '@angular/material/menu';
import {MatSelectModule} from '@angular/material/select';
import { FormControl, FormBuilder, FormGroup } from "@angular/forms";
import { ProjectProxyService } from 'src/app/services/project-proxy.service';
import {MatRadioModule} from '@angular/material/radio';
import { ProjectStateService, ViewState } from 'src/app/services/project-state.service';
import { Project } from 'src/app/_models/project';
import { EditorService } from 'src/app/services/editor.service';

@Component({
  selector: 'description-item',
  templateUrl: './description-item.component.html',
  styleUrls: ['./description-item.component.scss'],
  host: {
    '[class.description-ar]': '!isAR',
    '[class.single-view]': 'descriptionService.isSingle'
  },
  animations: [ swipeDescription ]
})
export class DescriptionItemComponent implements OnInit, OnChanges {
  ViewState = ViewState;
  @Input() point: MagicPoint = null;
  @Input() swipeUpState: boolean = false;
  @Input() active: boolean = false;
  @Input() walkthroughViewer: boolean = false;
  @Input() listView: boolean = true;
  @Output() swiperActivated: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() toggleSingle: EventEmitter<any> = new EventEmitter<any>();
  @Output() typeSelectionEvent: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild("widgetMenu", {static:false}) widgetMenu: MatMenu;
  @ViewChild('descriptionElement', {static:false}) descriptionElement;

  isMoreOpened: boolean = false;
  state: string = AppConsts.descState.auto;
  swipeDemo: string = '';
  items: DescriptionItemDto[] = [];
  id: string;
  sphiraMarkId: string;
  selectedType: string;
  labsFeatures: Boolean = false;
  placeholderTitle: string = "Tap to edit";
  viewState: ViewState;
  currentProject: Project;

  get isAR() {return this.nativeService.isAR;}
  get isMobile() {return this.nativeService.isMobile;}
  get hasWriteAccess() {return this.projectService.hasWriteAccess;}
  get canEdit(){return this.projectService.hasWriteAccess;}

  constructor(
    private magicService: MagicService,
    private pointService: PointService,
    private nativeService: NativeService,
    private magicPointService: MagicPointService,
    public descriptionService: DescriptionService,
    private ref: ChangeDetectorRef,
    public projectService: ProjectProxyService,
    private projectStateService: ProjectStateService,
    private editorService: EditorService
  ) { 
    this.id = CommonService.getId();
  }

  ngOnInit() {
    
    this.magicPointService.swapChanged.subscribe(state => this.setState(state));

    if(localStorage.getItem('labsFeatures') == "true"){
      this.labsFeatures = true;
    }

    this.projectStateService.getSelected().subscribe(project => {
      this.currentProject = project;
    });

    this.projectStateService.getViewState().subscribe(val => {
      this.viewState = val;
      switch(val) {
        case ViewState.editor:

          break;
        case ViewState.viewer:

          break;
        case ViewState.static:

          break;
      }
    });

    this.descriptionService.getCreatedItems().subscribe(created => {
      if(created.id == this.id) {
        this.items.push(created);
        this.widgetMenu.close.emit();
        this.saveDescription();
      }
    });

    this.descriptionService.getUpdatedItems().subscribe(updated => {
      if(updated.item.id == this.id) {
        this.items[updated.index] = updated.item;
        this.saveDescription();
      }
    });
  }

  ngOnChanges($event: SimpleChanges): void {
    for (let propName in $event) {
      if (propName === 'swipeUpState') {
        if(this.swipeUpState){
          this.state = "up";
        } else {
          this.state = "down";
        }
      }
    }

    if(!$event.point) {
      return;
    }
    this.point = $event.point.currentValue;
  }
  
  setState(state: string): void {
    if(!this.isAR) {
      this.state = AppConsts.descState.auto;
      return;
     }
    
    this.state = state;
    this.magicPointService.isCreationAvailable = (this.state == AppConsts.descState.down);
  }

  expand(): void {
    if(!this.isAR) {
      this.descriptionService.isSingle = !this.descriptionService.isSingle;
      this.toggleSingle.emit(this.point);
      this.magicPointService.select(this.point);
    } else {
      this.state = AppConsts.descState.up;
    }
  }

  close(): void {
    this.toggleSingle.emit(null);
  }

  scale(factor: number): void {
    this.nativeService.scaleMagicPoint(this.point._id, factor);
  }

  save($event: DescriptionItemDto): void {
    this.items.push($event);
    this.saveDescription();
  }

  delete(): void {
    this.magicService.deleteMagic(this.point).subscribe(res => {
      this.magicPointService.pointDeleted.next(null);
    }, err => {
      console.log("error deleting magic point", err);
    });
  }

  updateSphiraMarkId(id: string){
    this.point.objectTrackingInformation = {
      src: id,
      anchorType: "sphiramarker",
      width: 0
    };
    console.log(this.point.objectTrackingInformation);
    this.magicService.saveMagic(this.point).subscribe(res => {
      this.magicPointService.pointDeleted.next(null);
    }, err => {
      console.log("error deleting magic point", err);
    });
  }

  assignAsTemplate(): void {
    this.currentProject.template = this.point.content;
    this.projectService.update(this.currentProject).subscribe(project => {
      this.currentProject = project;
    });
  }

  fillWithTemplate(): void {
    this.point.content = this.currentProject.template;
    console.log(this.point.content);
    this.magicService.saveMagic(this.point).subscribe(point => {
      this.point = point;
      this.editorService.rerender(point);
    });
  }

  openMore(): void {
    this.isMoreOpened = !this.isMoreOpened;
    if(this.isMoreOpened){this.state = AppConsts.descState.down;} else {this.state = AppConsts.descState.up;}
  }

  updateTitle($event: any): void {
    this.point.title = $event.target.value;
    this.magicService.saveMagic(this.point).subscribe(
        result => { console.log("Title updated: " + result) }, 
        err => { console.log("Error updating title", err); }
      );
  }

  updateDescription($event: any): void {
    this.items[$event.index] = $event.item;
    this.saveDescription();
  }

  typeSelectionChanged(): void {
    console.log("type changed");
    this.magicService.saveMagic(this.point).subscribe(
      result => { 
        console.log(result);
        this.typeSelectionEvent.emit();
      }, 
      err => { console.log("Error updating type", err); }
    );
  }

  saveDescription(): void {
    if(!this.point && this.items) {
      return;
    }

    this.point.content = this.descriptionService.formatDescription(this.items);

    this.magicService
        .updateMagicContent(this.point._id, this.point.content)
        .subscribe(result => {
          this.point.content = result.content;
        });
  }

  resetSwipeDemo(): void {
    this.swipeDemo = '';
  }

  deleteDescription($event: number): void {
    this.items.splice($event, 1);
    this.saveDescription();
  }

  reorder($event): void {
    moveItemInArray(this.items, $event.previousIndex, $event.currentIndex);
    this.saveDescription();
  }

  onSwiper(state: boolean): void {
    this.swiperActivated.emit(state);
  }

}