import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Project } from '../../../_models/project';
import { MatBottomSheetRef, MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { ProjectMoreSheetComponent } from '../project-more-sheet/project-more-sheet.component';
import { NativeService } from '../../../services/native.service';
import { ProjectService } from '../project/project.service';
import { ShareComponent } from 'src/app/components/share/share.component';
import { DialogProjectNameComponent } from 'src/app/dialogs/dialog-project-name/dialog-project-name.component';
import { WalkthroughService } from 'src/app/services/walkthrough.service';

@Component({
  selector: 'project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
  host: {
    '[class.add-project]': '!project',
  }
})
export class ProjectComponent implements OnInit {
  bottomSheetRef: MatBottomSheetRef;
  labsFeatures: boolean = false;
  hasOpenWalkthrough: boolean = false;

  @Input() project: Project;
  get isAR() {return this.nativeService.isAR;}
  
  constructor(private bottomSheet: MatBottomSheet,
              private dialog: MatDialog,
              private projectService: ProjectService, 
              private nativeService: NativeService,
              private walkthroughService: WalkthroughService
            ) {
  }

  ngOnInit() { 
    if(localStorage.getItem('labsFeatures') == "true"){
      this.labsFeatures = true;
    }

    this.walkthroughService.getAllWalkthroughsForProject(this.project._id).subscribe(walkthroughs => {
      for(var i=0;i<walkthroughs.length;i++){
        if(walkthroughs[i].start == walkthroughs[i].end){
          this.hasOpenWalkthrough = true;
        }
      }
    });
  }

  openMoreSheet(): void {
    this.bottomSheetRef = this.bottomSheet.open(ProjectMoreSheetComponent, {
      data: { project: this.project }
    });
  }

  rename(): void {

    if(!this.project.title) {
      return;
    }

    const dialogRef = this.dialog.open(DialogProjectNameComponent, {
      width: '300px',
      data: {title: this.project.title}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined){
        this.project.title = result;
        this.projectService.update(this.project);
        this.dismiss();
      }
    });
  
  }

  inspect(): void {
    window.open("http://localhost:8080&project="+this.project._id, '_blank');
  }



  dismiss(): void {
    if(this.bottomSheetRef && this.bottomSheetRef.dismiss) {
      this.bottomSheetRef.dismiss();
    }
  }
}
