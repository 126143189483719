import { Injectable } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AppConsts } from '../_models/app-consts';


@Injectable({
    providedIn: 'root'
})
export class AuthFormService {

    constructor(private formBuilder: FormBuilder) { }

    getForm(): FormGroup {
        return this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(6)]]
        });
    }

    getAuthError(error: any): string {
        let message = '';

        if (error.status == AppConsts.httpErrors.badRequest) {
            message = AppConsts.errors.invalidEmailOrPassword;
        }
        
        return message;
    }

    getFormError(form: FormGroup): string {
        let message = '';
        let emailErrors = form.controls.email.errors;
        let passwordErrors = form.controls.password.errors;
        
        message = this.appendError(emailErrors && emailErrors.required, AppConsts.errors.email.required, message);
        message += this.appendError(emailErrors && emailErrors.email, AppConsts.errors.email.invalid, message);
        message += this.appendError(emailErrors && emailErrors.email, AppConsts.errors.email.notvalidated, message);
        message += this.appendError(passwordErrors && passwordErrors.required, AppConsts.errors.password.required, message);
        message += this.appendError(passwordErrors && passwordErrors.minlength, AppConsts.errors.password.minlength, message);

        return message;
    }

    private appendError(condition: boolean, error: string, message: string): string {
        if (!condition) {
            return "";
        }

        return message ? `\n${error}` : error;
    }
}