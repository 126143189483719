import { Injectable, HostListener } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AppConsts } from '../_models/app-consts';

declare global {
  interface Window { Unity: any; }
}

@Injectable({
  providedIn: 'root'
})
export class NativeService {

  events: Subject<any> = new Subject<any>();
  isAR: boolean = false;          // can be changed to non-ar even if ar capable
  isARCapable: boolean = false;   // should not be modified
  isMobile: boolean = window.innerWidth <= AppConsts.mobileWidth;

  constructor() { 
    var self = this;    
    window.Unity = window.Unity || {};
    window.Unity.call = window.Unity.call || function(msg){ console.log("nativecall",msg); };

    this.events.subscribe($event => {
      if($event.msg == "answerIfARCapable") {
        self.isAR = true;
        self.isARCapable = true;
      }
    });
    setTimeout(this.checkIfARCapable, 200); // wait window.Unity to be initialized
  }

  openURL(url): void {
    if(this.isAR){
      window.Unity.call(JSON.stringify({
        msg: "openURL",
        payload: url
      }));
    } else {
      window.open(url, "_blank");
    }
  }

  initProject(project): void {
    window.Unity.call(JSON.stringify({
      msg: "initProject",
      payload: JSON.stringify(project)
    }));
  }

  initMagicPoints(magicPoints): void {
    window.Unity.call(JSON.stringify({
      msg: "initMagicPoints",
      payload: JSON.stringify({items: magicPoints})
    }));
  }

  magicPointSelected(id): void {
    window.Unity.call(JSON.stringify({
      msg: "magicPointSelected",
      payload: JSON.stringify({id: id})
    }));
  }

  deleteMagicPoint(magic): void {
    window.Unity.call(JSON.stringify({
      msg: "deleteMagicPoint",
      payload: JSON.stringify(magic)
    }));
  }

  scaleMagicPoint(id, factor): void {
    window.Unity.call(JSON.stringify({
      msg: "scaleMagicPoint",
      payload: JSON.stringify({
        id: id,
        factor: factor
      })
    }));
  }

  touchEvent(event): void {
    window.Unity.call(JSON.stringify({
      msg: "touchEvent",
      payload: JSON.stringify(event)
    }));
  }

  placeMode(event): void {
    window.Unity.call(JSON.stringify({
      msg: "placeMode",
      payload:JSON.stringify(event)
    }));
  }

  createSpatialAnchor(): void {
    window.Unity.call(JSON.stringify({
      msg: "createSpatialAnchor",
      payload:JSON.stringify("")
    }));
  }

  startSpatialAnchorSession(): void {
    window.Unity.call(JSON.stringify({
      msg: "startSpatialAnchorSession",
      payload:JSON.stringify("")
    }));
  }

  endSession(): void {
    window.Unity.call(JSON.stringify({
      msg: "endSession",
      payload: ""
    }));
  }

  loggedIn(token): void {
    window.Unity.call(JSON.stringify({
      msg: "loggedIn",
      payload: token
    }));
  }

  debug(event): void {
    window.Unity.call(JSON.stringify({
      msg: "debug",
      payload:JSON.stringify(event)
    }));
  }

  checkIfARCapable(): void {
    window.Unity.call(JSON.stringify({
      msg: "checkIfARCapable",
      payload: ""
    }));
  }

  initHandler(): Observable<any> {
    window.addEventListener("nativeHandler", ($event : any) => {
      this.debug("received nativeHandler event: " + $event.detail.msg);
      this.events.next($event.detail);
    });
    return this.events.asObservable();
  }

  destroyHandler(): void {
    window.removeEventListener("nativeHandler", null);
  }
}
