import { Component, OnInit, Input } from '@angular/core';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { ChartOptions, ChartType, ChartDataSets, ChartAnimationOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import * as moment from 'moment';
import { unitOfTime } from 'moment'
import { AnalyticsPoint } from 'src/app/_models/analyticspoint';

@Component({
  selector: 'app-project-dash',
  templateUrl: './project-dash.component.html',
  styleUrls: ['./project-dash.component.scss']
})
export class ProjectDashComponent implements OnInit {

  @Input() selectedProjectId: string;

  totalViews: number = 0;
  averageTotalTime: number = 0;
  totalTime: number = 0;
  
  tableData: any[][] = [];
  analytics: AnalyticsPoint[] = [];

  

  constructor(
    private analyticsService: AnalyticsService
  ) { }

  ngOnInit() {
    this.updateAnalytics();
  }

  updateAnalytics(): void {
    this.analyticsService.getAnalyticsPointsForProject(this.selectedProjectId).subscribe(res => {
      console.log("from server", res);
      var projects = res.filter(res => res.dataType == "project");
      this.tableData.push(projects);
      this.tableData = this.tableData.slice(); // to trigger ngonchanges
      console.log(this.tableData);
      this.totalViews = this.tableData[0].length;

      this.averageTotalTime = 0;
      this.totalTime = 0;
      var self = this;
      projects.forEach(function(el) {
        self.totalTime += el.timespan;
      });
      this.averageTotalTime = this.totalTime / projects.length;
      this.averageTotalTime = Math.round(this.averageTotalTime / 1000); // convert to full seconds
      this.totalTime = Math.round(this.totalTime / 1000 / 60);

    }, err => {
      console.log(err);
    });
  }

}
