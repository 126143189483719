import { Injectable, EventEmitter } from '@angular/core'
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { Project } from '../_models/project';

@Injectable({
  providedIn: 'root'
})
export class ProjectStateService {

  projectSelected: BehaviorSubject<Project> = new BehaviorSubject<Project>(null);
  private viewState: BehaviorSubject<ViewState> = new BehaviorSubject<ViewState>(ViewState.static);

  constructor() { }

  // selected Project
  getSelected(): Observable<Project> {
    return this.projectSelected.asObservable();
  }

  select(project: Project): void {
    return this.projectSelected.next(project);
  }

  
  getViewState(): Observable<ViewState> {
    return this.viewState.asObservable();
  }

  setViewState(val: ViewState): void {
    return this.viewState.next(val);
  }  
}

export enum ViewState {
  static = 0,
  viewer = 1,
  editor = 2,
}