import { trigger, style, state, transition, animate, keyframes } from '@angular/animations';
import { AppConsts } from '../../../../_models/app-consts';

export const swipeDescription = trigger('verticalSwipe', [
  state(AppConsts.descState.down, style({
    height: '180px'
  })),
  state(AppConsts.descState.up, style({
    height: '70vh'
  })),
  state(AppConsts.descState.auto, style({
    height: '*'
  })),
  transition('down => up', animate('300ms ease-in-out')),
  transition('up => down', animate('300ms ease-in-out'))
]);

const swipeDemoKeyframes = [
  style({transform: 'translate3d(-15px, 0, 0)', offset: 0.1}),
  style({transform: 'translate3d(15px, 0, 0)', offset: 0.3}),
  style({transform: 'translate3d(-15px, 0, 0)', offset: 0.5}),
  style({transform: 'translate3d(15px, 0, 0)', offset: 0.7}),
  style({transform: 'translate3d(-15px, 0, 0)', offset: 0.9})
];

export const swipeDemo = [
  trigger('swipeDemo', [
    state('demo', style({})),
    transition("* => demo", animate(1000, keyframes(swipeDemoKeyframes)))
  ])
];