import { Injectable } from '@angular/core';
import { MagicService } from './magic.service';
import { MagicPoint } from '../_models/magicpoint';
import { WalkthroughService } from './walkthrough.service';
const EditorJS = require('editorjs');
const Paragraph = require('./../plugins/paragraph/');
const Checklist = require('@editorjs/checklist');
const Button = require('./../plugins/button');
const Textinput = require('./../plugins/textinput');
const Header = require('@editorjs/header');
const ImageTool = require('@editorjs/image');

@Injectable({
  providedIn: 'root'
})
export class EditorService {

  constructor(
    private magicService: MagicService,
    private walkthroughService: WalkthroughService
  ) { 
    var self = this;
    this.editor = new EditorJS({
      holder : document.createElement("div"),
      hideToolbar: false,
      tools: {
          paragraph: {
            class: Paragraph,
            inlineToolbar: true,
          },
          button: {
            class: Button,
          },
          checklist: {
            class: Checklist,
            inlineToolbar: false,
          },
          textinput: {
            class: Textinput,
            inlineToolbar: false
          },
          image: {
            class: ImageTool,
            config: {
              endpoints: {
                byFile: 'https://api.sphira.co/api/projects/tracking' // Your backend file uploader endpoint
                //byUrl: 'http://localhost:8008/fetchUrl', // Your endpoint that provides uploading by Url
              }
            }
          }
      },
      data: {},
      onChange: function() {
        self.onEditorChange();
      },
      onReady: () => {
        this.editor.configuration.holder.querySelector('.ce-toolbar__settings-btn').innerHTML = "<mat-icon>more_horiz</mat-icon>";
        window.setTimeout(()=>{
          //self.initSettingsHolder();
        }, 1000);
      }
    });

    this.walkthroughService.walkthroughState.subscribe(val => {
//      setTimeout(function(){
        if(val != undefined){
          console.log("received walkthrough state update", val);
          self.toggleStatic(!val);    // not static anymore when walkthrough running
          self.toggleViewer(true);
        }
  //    },2000);
    });
  }
  editor: any;
  point: MagicPoint = new MagicPoint();
  initialized: Boolean = false;
  lastHolder: HTMLElement = document.createElement("div");

  static: boolean = false;
  viewer: boolean = false;

  initSettingsHolder(): void {
    console.warn(this.editor.configuration);
    //document.querySelector('.settings-content').appendChild(this.editor.configuration.holder.querySelector('.ce-settings__plugin-zone'));
  }

  rerender(point: MagicPoint): void {
    this.point = point;

    var data = {};
    if (this.point.content != undefined) {
      data = JSON.parse(this.point.content);
    }
    this.editor.render(data);
    this.editor.toolbar.close();
  }


  initEditor(holder: HTMLElement, point: MagicPoint): void {
    this.point = point;

    var data = {};
    if (this.point.content != undefined && this.point.content != "") {
      console.log(this.point);
      data = JSON.parse(this.point.content);
    }

    console.log(this.editor.configuration.holder);
    holder.appendChild(this.editor.configuration.holder); // reparent editorjs to new dom element
    this.editor.render(data);
    console.log(this.editor.toolbar);
    this.editor.toolbar.close();
    this.toggleStatic(this.static);
    this.toggleViewer(this.viewer);

    if(!this.viewer && !this.static){this.initSettingsHolder();}
  }

  toggleStatic(val: boolean): void {
    this.static = val;
    var stateClass = "static";
    if(val){
      this.editor.configuration.holder.classList.add(stateClass);
    } else {
      this.editor.configuration.holder.classList.remove(stateClass);
    }
  }

  toggleViewer(val: boolean): void {
    this.viewer = val;
    var stateClass = "viewer";
    if(val){
      this.editor.configuration.holder.closest('app-editor').classList.add(stateClass);
    } else {
      this.editor.configuration.holder.closest('app-editor').classList.remove(stateClass);
    }
  }

  onEditorChange(): void {
    this.editor.save().then((savedData) => {
      console.log(savedData);
      this.point.content = JSON.stringify(savedData);
      
      if(this.walkthroughService.walkthroughRunning){
        console.log(this.point._id);
        this.walkthroughService.updateMagicSnapshot(this.point._id, savedData);
      } else {
        this.magicService.saveMagic(this.point).subscribe(magicpoint => {
          console.log("magicpoint content updated", magicpoint);
        });
      }
      
    });
  }
}
