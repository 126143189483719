import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {BehaviorSubject} from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { map, catchError } from 'rxjs/operators';
import { AppSettings } from '../app-settings';
import { Project } from '../_models/project';
import { Multimarker } from '../_models/multimarker';

@Injectable({
  providedIn: 'root'
})
export class ProjectProxyService {

  result : any;
  userId: string;
  token: string;
  httpOptions: {};
  currentProjectId: String;
  hasWriteAccess: Boolean;
  isStatic: Boolean;

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) { 
      this.userId = this.authenticationService.currentUserValue._id || "";
      this.token = this.authenticationService.currentUserValue.token || "";

      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Authorization': 'Token ' + this.token
        })
      }
    }

  addProject(title:String) {
    var language = localStorage.getItem("language");
    this.token = this.authenticationService.currentUserValue.token || "";
    this.userId = this.authenticationService.currentUserValue._id || "";
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Token ' + this.token
      })
    }
    return this.http.post<Project>(AppSettings.API_ENDPOINT+'/projects/save', JSON.stringify({"title": title, "language": language, "type": "document"}), this.httpOptions)
     .pipe(map(res => res))
  }

  getProjects(): Observable<Project[]> {
    this.token = this.authenticationService.currentUserValue.token || "";
    this.userId = this.authenticationService.currentUserValue._id || "";
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Token ' + this.token
      })
    }
    console.log(this.httpOptions);
    return this.http.get<any>(AppSettings.API_ENDPOINT+'/users/projects/'+this.userId, this.httpOptions)
     .pipe(map(res => res.projects.userProjects))
  }
  
  getProjectWithId(id: String): Observable<Project> {
    return this.http.get<any>(AppSettings.API_ENDPOINT+'/projects/get/'+id, this.httpOptions)
     .pipe(map(res => res))
  }

  getProjectFromMark(id: String): Observable<any> {
    return this.http.get<any>(AppSettings.API_ENDPOINT+'/mark/getproject/'+id, this.httpOptions)
     .pipe(map(res => res))
  }

  getMultimarkerFromSrc(multimarker: string): Observable<Multimarker> {
    return of(multimarker).pipe(map(response => <Multimarker> JSON.parse(response)));
  }

  delete(id:String): Observable<any> {
    return this.http.delete(AppSettings.API_ENDPOINT+ "/projects/"+id, this.httpOptions)
    .pipe(map(res => res))
  }

  update(project: Project) {
    return this.http.post<Project>(AppSettings.API_ENDPOINT+'/projects/save', JSON.stringify(project), this.httpOptions)
     .pipe(map(res => res))
  }

  uploadFile(formData: any){
    var httpFormOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Token '+this.token
      })};
    return this.http.post<any>(AppSettings.API_ENDPOINT+'/projects/tracking', formData, httpFormOptions)   //
    .pipe(map(res => res))
  }

  saveSphiraMark(markId: string, projectId: string) {
    var httpTextResponseOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Token '+this.token,
        'responseType': 'text'
      })};
    var url = AppSettings.API_ENDPOINT+'/mark/save/'+markId+'/'+projectId;
    return this.http.post<any>(url, null, httpTextResponseOptions);
    //return this.http.post<any>(url, this.httpOptions).pipe(map(res => res))
  }

  addCollaborator(email: string, projectId: string){
    return this.http.post<any>(AppSettings.API_ENDPOINT+'/projects/addcollaborator/'+projectId, JSON.stringify({email: email}), this.httpOptions)
    .pipe(map(res=>res));
  }

  getCollaborators(projectId: string) {
    return this.http.get<any>(AppSettings.API_ENDPOINT+'/projects/collaborators/'+projectId, this.httpOptions)
    .pipe(map(res=>res));
  }
}