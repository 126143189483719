import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Project } from '../../../_models/project';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

    private created: Subject<string> = new Subject<string>();
    private updated: Subject<Project> = new Subject<Project>();
    private deleted: Subject<string> = new Subject<string>();
    
    constructor() { }

    update(project: Project): void {
      this.updated.next(project);
    }

    getUpdated(): Observable<Project> {
      return this.updated.asObservable();
    }

    delete(id: string): void {
      this.deleted.next(id);
    }

    getDeleted(): Observable<string> {
      return this.deleted.asObservable();
    }

    create(title: string): void {
      this.created.next(title);
    }

    getCreated(): Observable<string> {
      return this.created.asObservable();
    }
}