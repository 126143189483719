import { MagicSnapshot } from './magicsnapshot';

export class Walkthrough {
    _id?: string;
    user : string;
    project: string;
    start: Date;
    end: Date;
    snapshot: MagicSnapshot[];
}
