import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Project } from 'src/app/_models/project';
import { AnalyticsPoint } from 'src/app/_models/analyticspoint';
import { AnalyticsService } from 'src/app/services/analytics.service';


@Component({
  selector: 'app-stats-widget',
  templateUrl: './stats-widget.component.html',
  styleUrls: ['./stats-widget.component.scss']
})
export class StatsWidgetComponent implements OnInit {

  @Input() project: Project;

  totalViews: number = 0;
  averageTotalTime: number = 0;
  totalTime: number = 0;
  
  tableData: any[][] = [];
  analytics: AnalyticsPoint[] = [];

  constructor(
    private analyticsService: AnalyticsService
  ) { }

  ngOnInit() { 
  }

  updateAnalytics(): void {
    this.analytics = [];
    this.totalTime = 0;
    this.totalViews = 0;
    this.averageTotalTime = 0;
  
    this.analyticsService.getAnalyticsPointsForProject(this.project._id).subscribe(res => {
      this.tableData = [];
      var projects = res.filter(res => res.dataType == "project");
      this.tableData.push(projects);
      this.tableData = this.tableData.slice(); // to trigger ngonchanges
      this.totalViews = this.tableData[0].length;

      this.averageTotalTime = 0;
      this.totalTime = 0;
      var self = this;
      projects.forEach(function(el) {
        self.totalTime += el.timespan;
      });
      this.averageTotalTime = this.totalTime / projects.length;
      this.averageTotalTime = Math.round(this.averageTotalTime / 1000); // convert to full seconds
      this.totalTime = Math.round(this.totalTime / 1000 / 60);

    }, err => {
      console.log(err);
    });
  }
  ngOnChanges() { 
    this.updateAnalytics();
  }

}
