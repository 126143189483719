import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { LoginComponent } from '../login/login.component';
import { RegisterComponent } from '../register/register.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ProjectProxyService } from 'src/app/services/project-proxy.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-register',
  templateUrl: './login-register.component.html',
  styleUrls: ['./login-register.component.scss']
})
export class LoginRegisterComponent implements OnInit {

  constructor(
    private authService: AuthenticationService,
    private projectProxyService: ProjectProxyService,
    private router: Router
  ) { }

  isLogin = true;

  startDemoMode(){
    localStorage.setItem("language", "en");
    var email = this.randomString();
    this.authService.register("temp_"+email+"@sphira.co", this.randomString())
    .pipe(first())
    .subscribe(
        data => {
          var self = this;
          window.setTimeout(function(){
            self.projectProxyService.addProject("Demo Mode").subscribe(project => {
              self.router.navigate(['/viewer', project._id]);
          });
          }, 0);
          
        },
        error => {
          
        });
  }

  randomString(): string {
    return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
  }

  ngOnInit() {

  }

}
