import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'magicpoint',
  templateUrl: './magicpoint.component.html',
  styleUrls: ['./magicpoint.component.scss']
})
export class MagicpointComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
