import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop'
import { MagicPoint } from '../../../_models/magicpoint';
import { MagicService } from '../../../services/magic.service';
import { MagicPointService } from '../../../services/magic-point.service';
import { ProjectProxyService } from 'src/app/services/project-proxy.service';

@Component({
  selector: 'app-magicpointlist',
  templateUrl: './magicpointlist.component.html',
  styleUrls: ['./magicpointlist.component.scss']
})
export class MagicpointlistComponent implements OnInit {

  @Input() magicPoints: MagicPoint[];
  index: number = 0;
  point: MagicPoint = new MagicPoint();

  @Output() listItemSelected = new EventEmitter<MagicPoint>();
  @Output() listReordered = new EventEmitter();

  get hasWriteAccess() {return this.projectService.hasWriteAccess;}

  constructor(
    private magicService: MagicService,
    private magicPointService: MagicPointService,
    private projectService: ProjectProxyService
  ) { 

  }

  ngOnInit() {
    this.pointSelected();

  }

  pointSelected(): void {
    var self = this;
    this.magicPointService.getSelected().subscribe(point => {
      if(point && point._id != this.point._id) {
        this.index = point.step - 1;
        this.point = point;
      }
    });
  }

  selectListItem(magicPoint: any, index: number): void {
    this.index = index;
    this.point = magicPoint;
    this.magicPointService.select(magicPoint);
    this.listItemSelected.emit(null); 
  }

  // REORDER LIST
  magicListDrop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.magicPoints, event.previousIndex, event.currentIndex);
    console.log("magicList drop event", event.previousIndex, event.currentIndex);
    this.magicService.reorderMagicPoints(this.magicPoints).subscribe(res => {
      this.listReordered.emit(null);
    });
  }
}