import { Component, OnInit, Input } from '@angular/core';
import { MagicPoint } from '../../_models/magicpoint';
import { MagicService } from 'src/app/services/magic.service';
import { MagicPointService } from 'src/app/services/magic-point.service';
import { NativeService } from 'src/app/services/native.service';
import { DescriptionService } from 'src/app/containers/project/description/description.service';
declare var LeaderLine: any;
declare var AnimEvent: any;

@Component({
  selector: 'app-environment',
  templateUrl: './environment.component.html',
  styleUrls: ['./environment.component.scss']
})
export class EnvironmentComponent implements OnInit {

  @Input() point: MagicPoint;

  initialized: boolean = false;
  get isAR() {return this.nativeService.isAR;}
  get isMobile() {return this.nativeService.isMobile;}

  constructor(
    private magicService: MagicService,
    private magicPointService: MagicPointService,
    private descriptionService: DescriptionService,
    private nativeService: NativeService
  ) { }

  ngOnInit() {
    
  }

}
