import { Asset } from './asset';

export class Project {
	_id: string;
	title: string;
	description: string;
	objectTrackingInformation?: {
		anchorType?: string,
		src?: string,
		width?: number
	};
	tags: string[];
	type: string = "document";
	user: string = "";
	asset: Asset;
	template?: string;
	canEdit: boolean = false;
	isPublic: boolean = false;
	isListed: boolean = false;
	gatherUserFeedback: boolean = false;
	feedbackEmailEnabled: boolean = false;
	feedbackEmailCall: string = "";
	feedbackEmail: string = "";
	language: string;
	protectionPassword: string;
	lastUpdated: string;
	collaboratingUsers: string[];
	notificationsEnabled?: boolean = false;
}
