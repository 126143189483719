import { Component, OnInit, Input } from '@angular/core';
import { Project } from 'src/app/_models/project';

@Component({
  selector: 'app-reports-widget',
  templateUrl: './reports-widget.component.html',
  styleUrls: ['./reports-widget.component.scss']
})
export class ReportsWidgetComponent implements OnInit {

  @Input() project: Project;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {

  }

}
