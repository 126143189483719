import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { ProjectProxyService } from '../../services/project-proxy.service';
import { DescriptionItemDto } from '../../containers/project/description/description.service';

@Component({
  selector: 'upload-form',
  templateUrl: './upload-form.component.html',
  styleUrls: ['./upload-form.component.scss']
})
export class UploadFormComponent implements OnInit, OnChanges {
  
  @Output() uploaded: EventEmitter<any> = new EventEmitter<any>();
  uploadForm = this.formBuilder.group({ file: String });
  loading: boolean = false;

  constructor(private formBuilder: FormBuilder,
              private projectService: ProjectProxyService,
              private ng2ImgMaxService: Ng2ImgMaxService)
              { }

  ngOnInit() { }

  ngOnChanges() { }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.uploadForm.get('file').setValue(file);
      this.loading = true;
      const formData = new FormData();
      this.ng2ImgMaxService.resize([this.uploadForm.get('file').value], 1000, 600).subscribe((result)=>{
        formData.append('file', result);
        this.projectService.uploadFile(formData).subscribe(res => {
          this.loading = false;
          console.log(res);
          this.uploaded.emit({url: res.url});
        }, err => {
          this.loading = false;
          console.log(err);
        })
      });
    }
  }
}