import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, style, transition, animate, state, keyframes} from '@angular/animations';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatMenuTrigger } from '@angular/material/menu';
import { ProjectMoreSheetComponent } from '../project-more-sheet/project-more-sheet.component';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Project } from '../../../_models/project';
import { ProjectProxyService } from '../../../services/project-proxy.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { ProjectService } from '../project/project.service';
import { SettingsComponent } from '../../../containers/settings/settings.component';
import { QrscannerComponent } from '../../../components/qrscanner/qrscanner.component';
import { ProjectStateService } from 'src/app/services/project-state.service';
import { DialogProjectNameComponent } from 'src/app/dialogs/dialog-project-name/dialog-project-name.component';
import { NativeService } from 'src/app/services/native.service';
import { ViewerService } from '../../viewer/viewer.service';
import { Subject, Observable, Subscription } from 'rxjs';
import { ProfileSettingsComponent } from '../../profile-settings/profile-settings.component';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
  animations: [
    trigger('slideIn', [
      state('open', style({
        transform: 'translateY(300px)'
      })),
      state('closed', style({
        transform: 'translateY(0px)'
      })),
      transition('closed=>open', animate('300ms cubic-bezier(0.175, 0.885, 0.32, 1.275)')),
      transition('open=>closed', animate('200ms cubic-bezier(0.6, -0.28, 0.735, 0.045)'))
    ]),
    trigger('zoomIn', [
      state('closed', style({
        transform: 'scale(1)',
        opacity: 1
      })),
      state('open', style({
        transform: 'scale(0.8)',
        opacity: 0
      })),
      transition('closed=>open', animate('400ms cubic-bezier(0.175, 0.885, 0.32, 1.275)')),
      transition('open=>closed', animate('400ms cubic-bezier(0.6, -0.28, 0.735, 0.045)'))
    ]),
    trigger('reveal', [
      state('closed', style({
        height: '120px'
      })),
      state('open', style({
        height: '*'
      })),
      transition('closed=>open', animate('400ms cubic-bezier(0.175, 0.885, 0.32, 1.275)')),
      transition('open=>closed', animate('400ms cubic-bezier(0.6, -0.28, 0.735, 0.045)'))
    ])
  ]
})
export class ProjectsComponent implements OnInit {
  
  projects: Project[] = [];
  userId: String = this.authenticationService.currentUserValue._id;
  sphiraMark: string = "";
  loading: Boolean = true;
  currentProject: Project;
  nativeHandlerSubscription: Subscription;

  favoriteTags: string[] = ["Weinheim Galerie", "Gateway Gardens", "Tate Modern"];

  constructor(
    private scanQrDialog: MatDialog,
    private dialog: MatDialog,
    private settingsDialog: MatDialog,
    private authenticationService: AuthenticationService,
    private projectProxyService: ProjectProxyService,
    private projectService: ProjectService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private projectStateService: ProjectStateService,
    public nativeService: NativeService,
    private viewerService: ViewerService
    ) { }

  ngOnInit() {
    this.nativeHandlerSubscription = this.nativeService.initHandler().subscribe(event => {
      if(event.msg == "markerDetected" && event.payload != "") {
        this.projectProxyService.getProjectFromMark(event.payload).subscribe(obj => {
          if(obj.project != null){
           // this.router.navigateByUrl("/viewer/"+obj.project+"/edit?markerDetected=true");
            this.router.navigate(['/viewer/'+obj.project], { queryParams: { markerDetected: 'true' } });
          }
        });
      }
    });
    this.nativeService.checkIfARCapable();

    this.getProjects();
    this.nativeService.initProject(new Project);

    this.projectService.getDeleted().subscribe(id => {
      this.delete(id);
    });

    this.projectService.getUpdated().subscribe(updated => {
      this.update(updated);
    });

    this.projectStateService.getSelected().subscribe(project => {
      this.currentProject = project;
    });

    setTimeout(()=>{
      this.activatedRoute.queryParams.subscribe(params => {
        let createProjectWithMark = params['newprojectwithmark'];
        if(createProjectWithMark){
          var create = confirm("This marker is empty. Create a new project with it?");
          if(create){
            this.sphiraMark = createProjectWithMark;
            this.add();
          }
        }
      });
    }, 100);
    
  }

  ngOnDestroy() {
    this.nativeHandlerSubscription.unsubscribe();
  }

  getProjects(): void {
    this.projectProxyService.getProjects().subscribe(projects => {
      this.projects = projects.reverse();
      this.loading = false;
    }, err => {
      console.log(err);
      var self = this;
      setTimeout(()=>{
        self.getProjects();
      }, 500);
    });
  }

  update(project: Project): void {
    this.projectProxyService.update(project).subscribe(result => { });
  }

  delete(id: string): void {
    this.projectProxyService.delete(id).subscribe(result => {
      if(result.deleted) {
        this.projects = this.projects.filter(x => x._id != id);
      }
    });
  }

  openSettings(): void {
    const settingsDialogRef = this.settingsDialog.open(SettingsComponent, {
      width: '800px'
    });
  }

  openProfileSettings(): void {
    const settingsDialogRef = this.settingsDialog.open(ProfileSettingsComponent, {
      width: '800px'
    });
  }
  
  selectTag(tag: string): void {
    alert(tag);
  }

  filterOwnProjects(active: boolean) {
    if(active){
      return this.projects.filter(x => x.user == this.userId).slice().reverse();
    } else {
      return this.projects.filter(x => x.user != this.userId).slice().reverse();
    }
    
  }

  openQrScanner(): void {
    const dialogRef = this.scanQrDialog.open(QrscannerComponent, {
      width: '800px'
    });
  }

  selectProject(project): void {
    if(this.nativeService.isMobile) {
      this.router.navigateByUrl("/detail/"+project._id);
    } else {
      this.projectStateService.select(project);
    }
  }

  add(): void {

      const dialogRef = this.dialog.open(DialogProjectNameComponent, {
        width: '300px',
        data: {title: ''}
      });
  
      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');

        var title = result;

        if(!title) {
          return;
        }
    
        this.projectProxyService.addProject(title).subscribe(project => {
          this.getProjects();
          if(this.sphiraMark != ""){    // the ID of the sphira marker that was scanned
            project.objectTrackingInformation = {
              anchorType: "sphiramarker",
              src: this.sphiraMark,
              width: 0.105
            };
            this.projectProxyService.saveSphiraMark(this.sphiraMark, project._id).subscribe(res => {console.log(res);});
            this.projectProxyService.update(project).subscribe(res => {
              this.sphiraMark = "";
              this.router.navigate(['/viwer', project._id, 'edit']);
            });
          } else {
            this.router.navigate(['/viewer', project._id, 'edit']);
          }
        });

      });

  }
}
