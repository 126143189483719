import { Component, OnInit, Input } from '@angular/core';
import { Project } from 'src/app/_models/project';

@Component({
  selector: 'app-start-walkthrough-widget',
  templateUrl: './start-walkthrough-widget.component.html',
  styleUrls: ['./start-walkthrough-widget.component.scss']
})
export class StartWalkthroughWidgetComponent implements OnInit {

  @Input() project: Project

  constructor() { }

  ngOnInit() {
  }

}
