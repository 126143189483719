import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Project } from '../../../_models/project';
import {MatCardModule} from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { ProjectStateService } from 'src/app/services/project-state.service';
import { DialogConfirmDeleteComponent } from 'src/app/dialogs/dialog-confirm-delete/dialog-confirm-delete.component';
import { DialogProjectNameComponent } from 'src/app/dialogs/dialog-project-name/dialog-project-name.component';
import { ProjectService } from '../project/project.service';
import { ProjectProxyService } from 'src/app/services/project-proxy.service';
import { NativeService } from 'src/app/services/native.service';

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss']
})
export class ProjectDetailComponent implements OnInit {

  currentProject: Project;
  selectedProject: String = this.activatedRoute.snapshot.paramMap.get('id')

  constructor(
    private projectStateService: ProjectStateService,
    private dialog: MatDialog,
    private projectService: ProjectService,
    private projectProxyService: ProjectProxyService,
    private activatedRoute: ActivatedRoute,
    public nativeService: NativeService
  ) { }

  ngOnInit() {
    this.nativeService.initProject(new Project);
    
    this.projectStateService.projectSelected.subscribe(project => {this.currentProject = project;});
    if(this.selectedProject != undefined){
      this.projectProxyService.getProjectWithId(this.selectedProject).subscribe(project => {
        this.projectStateService.select(project);
      });
    }

    this.projectStateService.getSelected().subscribe(project => {
      this.currentProject = project;
    });
  }

  delete(): void {
    const dialogRef = this.dialog.open(DialogConfirmDeleteComponent, {
      width: '300px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined){
        this.projectService.delete(this.currentProject._id);
      }
    });
  }

  rename(): void {

    if(!this.currentProject.title) {
      return;
    }

    const dialogRef = this.dialog.open(DialogProjectNameComponent, {
      width: '300px',
      data: {title: this.currentProject.title}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result != undefined){
        this.currentProject.title = result;
        this.projectService.update(this.currentProject);
        //this.dismiss();
      }
    });
  
  }

}
