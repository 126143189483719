import { Component, OnInit, ViewChild } from '@angular/core';
import {Location} from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { ProjectProxyService } from 'src/app/services/project-proxy.service';
import { Project } from 'src/app/_models/project';



@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})

export class ReportsComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private projectService: ProjectProxyService,
    private _location: Location
  ) { }

  selectedProject: String = this.activatedRoute.snapshot.paramMap.get('id');
  project: Project;

  ngOnInit() {

    this.projectService.getProjectWithId(this.selectedProject).subscribe(data => {
      this.project = data;
    });

  }

  goBack(): void {
    this._location.back();
  }

}
