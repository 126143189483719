import { Component, OnInit, ViewChild, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, style, transition, animate, state} from '@angular/animations';
import { MagicPoint } from '../../_models/magicpoint';
import { Project } from '../../_models/project';
import { ProjectProxyService } from '../../services/project-proxy.service';
import { DescriptionComponent } from '../project/description/description.component';
import { MagicService } from '../../services/magic.service';
import { NativeService } from '../../services/native.service';
import { ViewerService } from './viewer.service';
import { MagicPointService } from 'src/app/services/magic-point.service';
import { AppConsts } from '../../_models/app-consts';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { TranslateService } from '@ngx-translate/core';
import { Walkthrough } from '../../_models/walkthrough';
import { MagicSnapshot } from '../../_models/magicsnapshot';
import { MatDialog } from '@angular/material/dialog';
import { WalkthroughService } from 'src/app/services/walkthrough.service';
import { ProjectSettingsComponent } from '../project/project-settings/project-settings.component';
import { EditorService } from 'src/app/services/editor.service';
import { ProjectStateService, ViewState } from 'src/app/services/project-state.service';
import { jsonpFactory } from '@angular/http/src/http_module';

@Component({
  selector: 'app-viewer',
  host: {
    '[class.app-ar]': '!isAR'
  },
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.scss'],
  animations: [
    trigger('slideIn', [
      state('open', style({
        transform: 'translateY(300px)'
      })),
      state('closed', style({
        transform: 'translateY(0px)'
      })),
      transition('closed=>open', animate('300ms cubic-bezier(0.175, 0.885, 0.32, 1.275)')),
      transition('open=>closed', animate('200ms cubic-bezier(0.6, -0.28, 0.735, 0.045)'))
    ]),
    trigger('zoomIn', [
      state('closed', style({
        transform: 'scale(1)',
        opacity: 1
      })),
      state('open', style({
        transform: 'scale(0.8)',
        opacity: 0
      })),
      transition('closed=>open', animate('400ms cubic-bezier(0.175, 0.885, 0.32, 1.275)')),
      transition('open=>closed', animate('400ms cubic-bezier(0.6, -0.28, 0.735, 0.045)'))
    ]),
    trigger('reveal', [
      state('closed', style({
        height: '120px'
      })),
      state('open', style({
        height: '*'
      })),
      transition('closed=>open', animate('400ms cubic-bezier(0.175, 0.885, 0.32, 1.275)')),
      transition('open=>closed', animate('400ms cubic-bezier(0.6, -0.28, 0.735, 0.045)'))
    ])
  ],
}) 

export class ViewerComponent implements OnInit, OnDestroy {

  // Param from URL
  selectedProject: String = this.activatedRoute.snapshot.paramMap.get('id')
  instantDemoMode: Boolean = false;
  @Input() project: Project = new Project();

  walkthroughViewer: Boolean = false;
  walkthrough: Walkthrough;
  magicSnapshots: MagicSnapshot[];

  magicPoints: MagicPoint[];
  currentPoint: MagicPoint;

  isDescriptionOpen: Boolean = false
  isToastOpen: Boolean = false
  isVotingOpen: Boolean = false
  status: String = ""

  magicListOpen: Boolean = false
  settingsOpen: Boolean = false
  magicMoreOpen: Boolean = false
  listView: Boolean = false

  loading: Boolean = true

  inspectorOn: Boolean = false

  waitingForMarker: Boolean = false
  //waitingForDestination: Boolean = false
  specifyMarker: Boolean = false

  placeMode: Boolean = false;
  width: number;

  isDescriptionOpened: boolean = false;
  scanProgress: Number = 0;
  interactionCounter: number = 0;

  viewer: boolean = false;
  viewState: ViewState;
  viewManuValue: string = "viewer";

  projectHasScreenshots: boolean = false;

  get hasWriteAccess() {return this.projectService.hasWriteAccess;}
  get isMobile() {return this.nativeService.isMobile;}
  get isAR() {return this.nativeService.isAR;}
  get isARCapable() {return this.nativeService.isARCapable;}
  
  get walkthroughRunning() {return this.walkthroughService.walkthroughRunning;}
  set walkthroughRunning(state) {this.walkthroughService.walkthroughRunning = state;}

  @ViewChild('descriptionView', {static:false}) descriptionView: DescriptionComponent;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public projectService: ProjectProxyService,
    private magicService: MagicService,
    public nativeService: NativeService,
    private viewerService: ViewerService,
    public magicPointService: MagicPointService,
    private authenticationService: AuthenticationService,
    private analyticsService: AnalyticsService,
    private translate: TranslateService,
    private settingsDialog: MatDialog,
    private walkthroughService: WalkthroughService,
    private editorService: EditorService,
    private projectStateService: ProjectStateService
    ) { 
      this.magicPointService.pointDeleted.subscribe(point => {
        this.magicPointDeleted();
      });
    }

    ngOnInit() {

      this.activatedRoute.queryParamMap.subscribe(params => {
        if(params['params']['markerDetected'] == 'true'){
          var self = this;
          setTimeout(function(){
            self.waitingForMarker = false;
            self.showToast("Marker detected");
          },500);

        }
      });

      this.projectStateService.getViewState().subscribe(state => {
        this.viewState = state;
        if(state == ViewState.editor) {this.viewManuValue == "editor";}
        if(state == ViewState.viewer) {this.viewManuValue == "viewer";}
      });

      this.nativeService.initHandler().subscribe(event => {
        this.viewerService.handle(this, event);
      });

      if(!this.nativeService.isARCapable) {
        this.listView = true;
      }

      this.walkthroughService.walkthroughState.subscribe(state => {
        if(state == false){
          this.getProject();
        }
      });
  
      this.selectedProject = this.activatedRoute.snapshot.paramMap.get('id'); 
      
      if(this.isAR){
        this.waitingForMarker = true;
        this.showToast("Initializing camera");
      } else {
        this.waitingForMarker = false; this.specifyMarker = false;
      }

      // Detect if ID is walkthrough or not
      this.walkthroughService.getWalkthroughById(this.selectedProject).subscribe(walkthrough => {
          console.log("id is walkthrough");
          this.selectedProject = walkthrough.project;
          this.walkthrough = walkthrough;
          this.magicSnapshots = walkthrough.snapshot;
          this.walkthroughViewer = true;
          this.viewer = true;
          this.projectService.isStatic = true;
          this.projectStateService.setViewState(ViewState.static);
          this.getProject();
      }, err => {
          console.log(err);
          console.log("id is not a walkthroug");
          this.walkthroughService.getAllWalkthroughsForProject(this.selectedProject).subscribe(walkthroughs => {
            var foundRunningWalkthrough = false;
            for(var i=0;i<walkthroughs.length;i++){
              if(walkthroughs[i].start == walkthroughs[i].end){
                this.walkthroughRunning = true;
                foundRunningWalkthrough = true;
                this.walkthrough = walkthroughs[i];
                this.magicSnapshots = walkthroughs[i].snapshot;
                this.walkthroughService.resumeWalkthrough(walkthroughs[i]);
              }
            }
            if(!foundRunningWalkthrough){
              this.walkthroughRunning = false;
            }
            
          });
          this.getProject();
      });
   
      if(this.activatedRoute.snapshot.paramMap.get('edit') == "edit"){
        this.projectStateService.setViewState(ViewState.editor);
        this.toggleViewer(false);
      } else {
        this.projectStateService.setViewState(ViewState.static);
        this.toggleViewer(true);
      }
    
      
    }

    toggleViewer(val: boolean): void {
      this.viewer = val;
      this.editorService.toggleStatic(val); // first make it static, remove static when starting walkthrough
      this.editorService.toggleViewer(val);
    }
  
    openSettings(): void {
      const settingsDialogRef = this.settingsDialog.open(ProjectSettingsComponent, {
        width: '800px',
        data: { project: this.project, scanProgress: this.scanProgress, isValid: this.projectDetailIsValid, demoMode: this.demoMode }
      });
    }

    //[project]="project" [scanProgress]="scanProgress" (isValid)="projectDetailIsValid($event)" (demoMode)="demoMode()"

  // RETREIVE MAGICPOINTS
  
  getMagicPoints(): void {
    this.magicService.getMagicForProject(this.selectedProject).subscribe(magicpoints => {
      

      var points = magicpoints.filter(point => point.environment != undefined).length;
      if(points > 0){this.projectHasScreenshots = true;}

      if(this.walkthroughViewer){
        for (let i=0; i<this.walkthrough.snapshot.length; i++) {
          console.log("replace magicpoint content with snapshots");
          var magicpoint = magicpoints.find(mp => mp._id === this.walkthrough.snapshot[i].magicpoint);
          if(magicpoint != undefined){
            magicpoint.content = JSON.stringify(this.walkthrough.snapshot[i].filledContent);
          }
        }
      }

      this.magicPoints = magicpoints;

      console.log(this.magicPoints);
      if(!this.isAR) {
        this.magicPointService.select(this.magicPoints[0]);

        if(magicpoints.length == 0){    // Create an empty magicpoint if non-AR and no magicpoint created yet
         // this.addPoint();
        }
      }
    })
  }

  magicPointDeleted(): void {
    this.magicService.getMagicForProject(this.selectedProject).subscribe(magicpoints => {   // get new list of magicpoints
      if(!magicpoints.length) {
        this.magicPoints = magicpoints;
        // TODO: Deselect magic point doesn`t work
        this.nativeService.magicPointSelected('');
        return;
      }

      this.magicService.reorderMagicPoints(magicpoints).subscribe(res => {                  // reorder with new indexes
        this.magicPoints = res;   // will also update description
      });
    })
  }

  updateScanProgress(state: number){
    this.scanProgress = state;
  }

  showToast(msg) {
    if(this.isAR) {
      this.isToastOpen = true;
      var self = this;
      setTimeout(function() {
        self.status = msg;
      }.bind(this), 200);

      setTimeout (() => {
        self.isToastOpen = false;
      }, 2000);
    }
  }

  editMarker() {
    this.waitingForMarker = false;
    this.settingsOpen = true;
  }

  scale(factor: number): void {
    var point = this.magicPointService.getSelected();
    this.nativeService.scaleMagicPoint(point, factor);
  }

getProject(): void {
      this.projectService.getProjectWithId(this.selectedProject).subscribe(project => {
      console.log("init, get project with id", project);
      this.loading = false;
      this.project = project;
      this.projectService.hasWriteAccess = project.canEdit;
      this.projectStateService.select(project);

      if(this.walkthroughViewer){this.projectService.hasWriteAccess = false;} // Turn off edit for viewing walkthrough
      
      this.translate.use(project.language);
      this.analyticsService.trackProject(project._id as string);

      // Show voting after 3 interactions
      var self = this;
      var currentUser = this.authenticationService.currentUserValue._id;
      var canVote = false;  // voting only for non editors, ar and if enabled
      if(!this.project.collaboratingUsers.includes(currentUser) && this.project.user != currentUser){ 
        canVote = true;
      }

      this.activatedRoute.queryParams.subscribe(params => {
        let startWalkthrough = params['startwalkthrough'];
        if(startWalkthrough){
          window.setTimeout(function(){
            self.walkthroughService.startWalkthrough(self.project._id);
            self.showToast("Walkthrough Started!");
          }, 1000);
        }
        // Remove query params
        this.router.navigate([], {
          queryParams: {
            startwalkthrough: null,
          },
          queryParamsHandling: 'merge'
        })
      });

      var initialDelay = true;
      window.setTimeout(function(){   // dirty delay, because slide changes get triggered on init
        self.interactionCounter = 0;
        //initialDelay = false;
      },100);

      this.magicPointService.getSelected().subscribe(point => {   // when MP changes  
          self.currentPoint = point;  
          self.interactionCounter = self.interactionCounter + 1;
          if(self.interactionCounter == 4 && canVote){
            self.isVotingOpen = true;   // show voting menu
          }
      });
   
      if(project.objectTrackingInformation){
        if(project.objectTrackingInformation.src && this.isAR) {
          this.waitingForMarker = true;
        } else {
          this.specifyMarker = true;
          
        }
      } else {
        if(this.project.canEdit && this.isARCapable){
          this.specifyMarker = true;
        }
      }
      console.log("Start Session");
      this.nativeService.initProject(this.project);
      this.getMagicPoints();

      if(this.activatedRoute.snapshot.paramMap.get('demoMode') == "true"){
        this.instantDemoMode = true;
        this.demoMode();
      }

      console.log(self);
    }, err => {
      console.log(err);

    });

}

  togglePlaceMode(): void {
    if(!this.placeMode) {
      this.nativeService.placeMode({
        state: "start"
      });
    } else {
      this.nativeService.placeMode({
        state: "stop"
      });
    }
    this.placeMode = !this.placeMode;
  }

  cancelPlaceMode(): void {
    this.nativeService.placeMode({
      state: "cancel"
    });
    this.placeMode = false;
  }

  projectDetailIsValid($event): void {
    // TODO: Fix after adding store
    console.log("project detail is valid");
    this.project = $event;
    this.specifyMarker = false;
    this.waitingForMarker = true;
  }

  descriptionOpened($event): void {
    this.isDescriptionOpened = $event;
  }

  closeMagicPointList() {
    if(this.isAR){
      this.magicListOpen = false;
    }
  }

  addPoint(): void {
    if(this.isAR) {
      this.togglePlaceMode();
    }
    else {
      this.createPoint();
    }
  }

  saveSphiraMarkAfterMarkerDetected(id: string): void {
    if(this.specifyMarker){  
      this.project.objectTrackingInformation = {
        anchorType: "sphiramarker",
        src: id,
        width: 0.105
      };
      this.projectService.saveSphiraMark(this.project.objectTrackingInformation.src, this.project._id).subscribe((data) => {
        console.log(data);
      },
      (error) => {
        console.log(error);
        if (error.status == 200){
          console.log("saved!")    // weirdly emits error
        }
      });
      this.projectService.update(this.project).subscribe(project => {
        this.nativeService.initProject(project);
      });
    }
    this.waitingForMarker = false;
    this.specifyMarker = false;
  }

  createPoint(): void {
    const point = { payload: {'title': '', 'matrix': [] } };
    this.viewerService.addPoint(this, point);
  }

  prevMagicPoint(): void {this.navMagicPoint(-1);}
  nextMagicPoint(): void {this.navMagicPoint(1);}

  navMagicPoint(index: number): void {
    var magicpoint = this.magicPointService.pointSelected.getValue();
    this.magicPointService.select(this.magicPoints[magicpoint.step + index -1]);
  }

  handleDescriptionPlace(): void {
    if(this.isAR) {
      var point = this.magicPointService.pointSelected.getValue();
      if(!point.matrix.length && this.project.canEdit) {
        if(!this.placeMode) {
          this.togglePlaceMode();
        }
      }
      else {
        if(this.placeMode) {
          this.cancelPlaceMode();
        }
      }
    }
  }

  toggleListView(): void {
    if(this.listView){
      this.listView = false;
    } else {
      this.listView = true;
    }
    console.log(this.listView);
  }

  demoMode(): void {
    this.specifyMarker = false;
    this.waitingForMarker = false;
    this.showToast("Demo Mode");
  }

  qrDetected(detail: string): void {
    //alert("qr detected " + detail);
  }

  ngAfterViewInit() {
    if(this.nativeService.isARCapable){
        // HANDLE TOUCH EVENTS
        var w = window,
        d = document,
        e = d.documentElement,
        g = d.getElementsByTagName('body')[0],
        x = w.innerWidth || e.clientWidth || g.clientWidth,
        y = w.innerHeight|| e.clientHeight|| g.clientHeight;

        var longPressTimer
        var initialTouch = [10, 10]
        var lastTouch = [10, 10]

        let self = this;
        document.getElementById('touch-background').ontouchstart = function (event: TouchEvent) {
          let x = event.changedTouches[0].pageX / document.documentElement.clientWidth;
          let y = 1 - (event.changedTouches[0].pageY / document.documentElement.clientHeight);
          initialTouch = [x, y];

          self.nativeService.touchEvent({
            state: "started",
            x:  x,
            y:  y
          });

          longPressTimer = window.setTimeout(function(){
            var dif = (initialTouch[0] - lastTouch[0]) + (initialTouch[1] - lastTouch[1])
            if(dif < 100) {
              self.nativeService.touchEvent({
                state: "longpress",
                x: 0,
                y: 0
              });
            }
          }, 400) 
        }

        document.getElementById('touch-background').ontouchmove = function (event) {
          let x = event.changedTouches[0].pageX / document.documentElement.clientWidth;
          let y = 1 - (event.changedTouches[0].pageY / document.documentElement.clientHeight);
          lastTouch = [x, y];
          self.nativeService.touchEvent({
            state: "moved",
            x: lastTouch[0],
            y: lastTouch[1]
          }); 
        }

        document.getElementById('touch-background').ontouchend = function (event) {
          let x = event.changedTouches[0].pageX / document.documentElement.clientWidth;
          let y = 1 - (event.changedTouches[0].pageY / document.documentElement.clientHeight);
          clearTimeout(longPressTimer);

          self.nativeService.touchEvent({
            state: "ended",
            x: x,
            y: y
          });
        }
    }
  }

  ngOnDestroy() {
    //this.nativeService.destroyHandler();
    this.translate.use(localStorage.getItem("language"));
    this.analyticsService.stopTracking();
    this.nativeService.endSession();
  }
}
