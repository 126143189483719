import { Component, OnInit, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatBottomSheetRef, MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { CreateDescriptionDialogComponent } from 'src/app/components/description/create-description-dialog/create-description-dialog.component';
import { DescriptionItemDto } from '../../description/description.service';
import { NativeService } from '../../../../services/native.service';
import { DescriptionTextMenuComponent } from '../description-text-menu/description-text-menu.component';
import { AppConsts } from '../../../../_models/app-consts';
import { ProjectProxyService } from 'src/app/services/project-proxy.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'description-text',
  templateUrl: './description-text.component.html',
  styleUrls: ['./description-text.component.scss']
})
export class DescriptionTextComponent implements OnInit {

  @Output() deleted: EventEmitter<number> = new EventEmitter<number>();
  @Input() item: DescriptionItemDto = new DescriptionItemDto({});
  @Input() index?: number = null;
  bottomSheetRef: MatBottomSheetRef;
  
  url: string;
  urlSafe: SafeResourceUrl;
  
  get hasWriteAccess() {return this.projectService.hasWriteAccess;}
  get isMobile() {return this.nativeService.isMobile;}
  get isAR() {return this.nativeService.isAR;}

  constructor(private dialog: MatDialog, 
              private nativeService: NativeService,
              private bottomSheet: MatBottomSheet,
              private projectService: ProjectProxyService,
              public sanitizer: DomSanitizer
              ) {

  }

  ngOnInit() {
    this.url = this.valueParameter('url');
    this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

  delete(): void {
    this.deleted.emit(this.index);
  }

  edit(): void {
    const dialogRef = this.dialog.open(CreateDescriptionDialogComponent, {
      width: '800px',
      data: { item: this.item, index: this.index }
    });
  }

  valueParameter(name : string) : string {
    return CommonService.getAllUrlParams(this.item.value)[name];
  }

  openBottom(): void {
    this.bottomSheetRef = this.bottomSheet.open(DescriptionTextMenuComponent, {
      data: { item: this.item }
    });

    this.bottomSheetRef.afterDismissed().subscribe((data) => {
      switch(data) {
        case AppConsts.menuState.edit: this.edit(); break;
        case AppConsts.menuState.delete: this.delete(); break;
        default: break;
      }
    });
  }
}
