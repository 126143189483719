import { Component, OnInit } from '@angular/core';
import {MatToolbarModule} from '@angular/material/toolbar';
import { SettingsComponent } from '../../containers/settings/settings.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  constructor(
    private settingsDialog: MatDialog
  ) { }

  ngOnInit() {
  }

  openSettings(): void {
    const settingsDialogRef = this.settingsDialog.open(SettingsComponent, {
      width: '800px'
    });
  }

}
