import { Component, OnInit } from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import {Tag} from '../../../_models/tag';
import { ProjectStateService } from 'src/app/services/project-state.service';
import { Project } from 'src/app/_models/project';
import { ProjectProxyService } from 'src/app/services/project-proxy.service';

@Component({
  selector: 'app-tags-widget',
  templateUrl: './tags-widget.component.html',
  styleUrls: ['./tags-widget.component.scss']
})
export class TagsWidgetComponent implements OnInit {

  constructor(
    private projectService: ProjectProxyService,
    private projectStateService: ProjectStateService
  ) { }

  project: Project;

  ngOnInit() {
    this.projectStateService.getSelected().subscribe(project => {
      this.project = project;
      this.tags = project.tags;
      console.log(this.tags);
    });
  }

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  tags: string[] = [];

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      this.tags.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
    this.saveTags();
  }

  remove(tag: string): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
    }
    this.saveTags();
  }

  saveTags(): void {
    this.project.tags = this.tags;
    this.projectService.update(this.project).subscribe(project => {
      console.log("saved tags", project);
    });
  }
}
