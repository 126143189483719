import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChartOptions, ChartType, ChartDataSets, ChartAnimationOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import { AnalyticsService } from 'src/app/services/analytics.service';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import * as moment from 'moment';
import { unitOfTime } from 'moment';
import { ProjectProxyService } from 'src/app/services/project-proxy.service';
import { Project } from 'src/app/_models/project';
import { Voting } from 'src/app/_models/voting';
import { AnalyticsPoint } from 'src/app/_models/analyticspoint';
import { ExportToCsv } from 'export-to-csv';

@Component({
  selector: 'app-voting-dash',
  templateUrl: './voting-dash.component.html',
  styleUrls: ['./voting-dash.component.scss']
})

export class VotingDashComponent implements OnInit {
  
  @Input() selectedProjectId: string;

  gatherUserFeedbackEnabled: boolean = false;
  feedbackEmailEnabled: boolean = false;
  feedbackEmailCall: string = "";
  votesWithEmail: Voting[];

  selectedProject: Project;
  datesBack: number = 7;
  filterBy: String = "week";

  totalUpVotes: number = 0;
  totalDownVotes:number = 0;

  feedbacks: Feedback[] = [];

  tableData: any[][] = [];


  constructor(
    private analyticsService: AnalyticsService,
    private activatedRoute: ActivatedRoute,
    private projectService: ProjectProxyService
  ) {}

  ngOnInit() {
    this.updateVotes();
    this.projectService.getProjectWithId(this.selectedProjectId).subscribe(res => {
      this.selectedProject = res;
      this.gatherUserFeedbackEnabled = res.gatherUserFeedback;
      this.feedbackEmailCall = res.feedbackEmailCall;
      this.feedbackEmailEnabled = res.feedbackEmailEnabled;
    });
  }

  feedbackEmailCallChange($event) {
    this.selectedProject.feedbackEmailCall = this.feedbackEmailCall;
    this.projectService.update(this.selectedProject).subscribe(res => {console.log(res);});
  }

  feedbackEmailEnabledChange($event){
    this.selectedProject.feedbackEmailEnabled = this.feedbackEmailEnabled;
    this.projectService.update(this.selectedProject).subscribe(res => {console.log(res);});
  }


  updateVotes(): void {
    this.analyticsService.getVotesForProject(this.selectedProjectId).subscribe(res => {


      // Total numbers
      this.totalUpVotes = res.filter(res => res.vote == 1).length;
      this.totalDownVotes = res.filter(res => res.vote == 0).length;

      this.tableData = [];

      // Filter votes by current time period
      this.tableData.push(res.filter(res =>  res.vote == 1));
      this.tableData.push(res.filter(res =>  res.vote == 0));
      this.tableData = this.tableData.slice();   // to trigger ngonchanges
      console.log(this.tableData);

      this.votesWithEmail = res.filter(res => res.email != undefined );

      // Count duplicates and sort
      var self = this;
      this.feedbacks = [];
      res.forEach(function(el) {
        if(el.vote == 1 || el.feedback == undefined){return}              // only filter downvotes with a comment
        var els = self.feedbacks.filter(res => res.name == el.feedback);
        if(els[0] == undefined){              // if feedback does not exist yet in array
          var newEl = new Feedback();         // create new one
          newEl.name = el.feedback;
          newEl.number = 1;
          self.feedbacks.push(newEl);
        } else {
          els[0].number = els[0].number + 1;  // increment number if already exists
        }
      });

      this.feedbacks.sort((a, b) => parseFloat(b.number) - parseFloat(a.number));
      
  });
  }

  toggleGatherUserFeedback(state: boolean){
    //this.gatherUserFeedbackEnabled = state;
    this.selectedProject.gatherUserFeedback = this.gatherUserFeedbackEnabled;
    this.projectService.update(this.selectedProject).subscribe(res => {console.log(res);});
  }

  generateCsv() {

    const options = { 
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true, 
      filename: this.selectedProject.title,
      showTitle: true,
      title: this.selectedProject.title,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };

  var votingsTable = [];
  this.votesWithEmail.forEach(function(el) {
    var votingTableEntry = new VotingTable();
    votingTableEntry.Email = el.email;
    if(el.vote == 0){
      votingTableEntry.Vote = "Helpful";
    } else {
      votingTableEntry.Vote = "Not Helpful";
    }
    votingTableEntry.Feedback = el.feedback;
    if(el.feedback == undefined){votingTableEntry.Feedback = "";}

    var date = new Date(el.createdAt);
    var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour:'numeric',minutes:'numeric' };
    votingTableEntry.Date = date.toLocaleDateString("de-DE", options);

    votingsTable.push(votingTableEntry);
  });
   
  const csvExporter = new ExportToCsv(options);
   
  csvExporter.generateCsv(votingsTable);

  }

  

}

class VotingTable  {
  Email: string = "";
  Vote: string = "";
  Feedback: string = "";
  Date: string = "";
}

class Feedback{
  number: any = 0;
  name: string = "";
  email: string = "";
}
