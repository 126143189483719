import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guard/auth-guard.service';
import { RegisterComponent } from './containers/auth/register/register.component';
import { LoginComponent } from './containers/auth/login/login.component';
import { ViewerComponent } from './containers/viewer/viewer.component';
import { ProjectSettingsComponent } from './containers/project/project-settings/project-settings.component';
import { ProjectsComponent } from './containers/project/projects/projects.component';
import { LoginRegisterComponent } from './containers/auth/login-register/login-register.component';
import { InspectorComponent } from './components/inspector/inspector.component';
import { AnalyticsComponent } from './containers/analytics/analytics.component';
import { AccountManagerComponent } from './containers/account-manager/account-manager.component';
import { DashboardComponent } from './containers/dashboard/dashboard.component';
import { ReportsComponent } from './containers/reports/reports.component';
import { ProjectDetailComponent } from './containers/project/project-detail/project-detail.component';

const routes: Routes = [
  {
    path: '',
    component: ProjectsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'viewer/:id',
    component: ViewerComponent
  },
  {
    path: 'viewer/:id/:edit',
    component: ViewerComponent
  },
  {
    path: 'detail/:id',
    component: ProjectDetailComponent
  },
  {
    path: 'projects',
    component: ProjectsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'inspector/:id',
    component: InspectorComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'reports/:id',
    component: ReportsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'auth',
    component: LoginRegisterComponent
  },
  {
    path: 'project-settings/:id',
    component: ProjectSettingsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'analytics/:id',
    component: AnalyticsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'account-manager',
    component: AccountManagerComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }