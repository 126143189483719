import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-submit-walkthrough',
  templateUrl: './dialog-submit-walkthrough.component.html',
  styleUrls: ['./dialog-submit-walkthrough.component.scss']
})
export class DialogSubmitWalkthroughComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogSubmitWalkthroughComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogSubmitWalkthroughData
  ) { }

  ngOnInit() {
    
  }

  onCancel(): void {
    this.dialogRef.close();
  }

}

export interface DialogSubmitWalkthroughData {
  sendNotification: boolean;
  notificationsEnabled: boolean;
}
