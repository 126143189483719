import { Component, OnInit, ViewChild, Input, SimpleChanges, SimpleChange, Output, EventEmitter, Inject, ElementRef } from '@angular/core';
import { SwiperComponent, SwiperDirective, SwiperConfigInterface, SwiperConfig } from 'ngx-swiper-wrapper';

import { MagicPoint } from '../../../_models/magicpoint';
import { NativeService } from '../../../services/native.service';
import { DescriptionService } from './description.service';
import { MagicPointService } from '../../../services/magic-point.service';
import { AppConsts } from '../../../_models/app-consts';
import { swipeDemo } from '../description-item/animations/swipe-description';
import { PageScrollService, PageScrollInstance } from 'ngx-page-scroll-core';
import { DOCUMENT } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';

@Component({
  selector: 'app-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss'],
  animations: [swipeDemo]
})
export class DescriptionComponent implements OnInit {

  @Input() walkthroughViewer: boolean = false;
  @Input() magicPoints: MagicPoint[];
  @Input() listView: boolean = false;
  @Output() isOpened = new EventEmitter();

  magicMoreOpen: boolean = false;
  currentIndex: number = 0
  swipeDemo: string = '';
  point: MagicPoint = new MagicPoint();
  swipeUpState: boolean = false;

  config: SwiperConfig = null;
  @Output() placeMode: EventEmitter<any> = new EventEmitter<any>();
  get isMobile() {return this.nativeService.isMobile;}

  @ViewChild('descScroll', {static:false})
  public descScroll: ElementRef;

  constructor(
    private nativeService: NativeService,
    private descriptionService: DescriptionService,
    private magicPointService: MagicPointService,
    @Inject(DOCUMENT) private document: any, private pageScrollService: PageScrollService
  ) { 
    this.config = this.descriptionService.getSwiperConfig("horizontal");
    this.descriptionService.isSingle = false;
  }

  @ViewChild(SwiperComponent, {static:false}) componentRef?: SwiperComponent;
  @ViewChild(SwiperDirective, {static:false}) directiveRef?: SwiperDirective;

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    if(!this.isMobile) {
       //this.listenScroll();
    }
    if(this.isMobile) {
      this.magicPointService.isActiveSwipeDemo = true;  // enables Animation of first description item to show swipability
      this.initPoints();
      this.pointSelected();
    }
  }

  selectPoint(point: MagicPoint): void {

    this.magicPointService.select(point);

  }

  touchMove(event:any): void {
    console.log(event);
    this.descriptionService.scrollEvents.next(event);
  }

  listenScroll(): void {
    this.descriptionService.initScroll().subscribe(result => {
      if(this.descriptionService.checkScroll) {
        return;
      }
      let id = this.descriptionService.getFromNodes(result.target.children, 120);
      if(id && this.point._id != id) {
        this.point = this.magicPoints.find(x => <string>x._id == id);
        this.magicPointService.select(this.point);
      }
      if(id==""){   // somehow does not detect first element, returns empty string instead
        this.magicPointService.select(this.magicPoints[0]);
      }
    });
  }

  public pointSelected(): void {
    this.magicPointService.getSelected().subscribe(point => {
      if(point && this.point._id != point._id) {
        this.point = point;
        this.currentIndex = point.step - 1;
        
        if(this.isMobile) {
          setTimeout(()=> {
            this.directiveRef.setIndex(this.currentIndex);
          }, 100);
        } else {
          setTimeout(()=> {
            this.scrollToDescription()
          });
        }
      }
    });
  }

  public scrollToDescription(): void {
    var description = this.document.getElementById(this.point._id);
    if(description) {
      const pageScrollInstance: PageScrollInstance = this.pageScrollService.create({
        document: this.document,
        scrollTarget: '#' + this.point._id,
        scrollViews: [this.descScroll.nativeElement],
        scrollOffset: 77,
        advancedInlineOffsetCalculation: true
      });
      this.descriptionService.checkScroll = true;
      this.pageScrollService.start(pageScrollInstance);
      setTimeout(() => this.descriptionService.checkScroll = false, 600);
    }
  }

  public onIndexChange(index: number): void {     // when swiped or changed from outside
    console.log("on swiper index change", index);
    this.currentIndex = index;
    if(!this.magicPoints) {
      return;
    }

    this.point = this.magicPoints.find( magicpoint => magicpoint.step === index + 1 );
    
    if(this.point && this.point._id) {
      this.magicPointService.select(this.point);
      this.magicPointService.swapChanged.next(AppConsts.descState.down);

      this.placeMode.emit({});
      if(this.point.matrix.length) {
        this.nativeService.magicPointSelected(this.point._id);
      }
    }
  }

  public initPoints(): void {
    setTimeout(()=>{ 
      this.onIndexChange(this.currentIndex);
      this.directiveRef.setIndex(this.currentIndex); 
    }, 100);  // Wait for swiper to update
    this.nativeService.initMagicPoints(this.magicPoints.filter(x => x.matrix.length));
    
    this.initSwipeDemo();
  }

  onSwipeState($event) {
    this.swipeUpState = $event;
    console.log(this.swipeUpState);
  }

  initSwipeDemo(): void {
    /*if(this.magicPointService.isActiveSwipeDemo) {
      this.magicPointService.isActiveSwipeDemo = false;
      setTimeout(() => {
        this.swipeDemo = AppConsts.descState.demo;
      }, 1000)
    }*/
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.isMobile) {
      for (let propName in changes) {
        if (propName === 'magicPoints' && this.magicPoints) {
          this.nativeService.debug("description: magicpoints changed, send init");
          this.directiveRef.update();
          this.initPoints();
        }
      }
    }

    this.pointSelected();
  }

  toggleSingle(): void {
    this.config = this.descriptionService.toggle(this.config);
  }

  onSwiper($event: boolean): void {
    this.config = this.descriptionService.activateSwiper(this.config, $event);
  }

  typeSelection(): void {
    //console.log("description: type selection event");
    this.initPoints();
  }
}