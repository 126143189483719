import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { NativeService } from './native.service'
import { MagicPoint } from '../_models/magicpoint';
import { AppSettings } from '../app-settings';
import { BaseService } from './base-proxy.service';
import { Http } from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class MagicService {

  token: String
  userId: String
  httpOptions: {}

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private nativeService: NativeService
    ) { 
      this.token = this.authenticationService.currentUserValue.token || "";
      this.userId = this.authenticationService.currentUserValue._id || "";

      this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Token '+this.token
      })
    }
  }

  saveMagic(magic: MagicPoint): Observable<MagicPoint> {
    let newMagic: MagicPoint = {
      id: this.authenticationService.currentUserValue._id,    // Add userId for authorization
      ...magic
    } 
    return this.http.post<MagicPoint>(AppSettings.API_ENDPOINT+'/magic/save', JSON.stringify(newMagic), this.httpOptions)
    .pipe(map(res => res))
  }

  getMagicForProject(id: String): Observable<MagicPoint[]> {
    return this.http.get<any>(AppSettings.API_ENDPOINT+'/magic/forproject/'+id, this.httpOptions)
     .pipe(map(res => res))
  }

  getMagicContent(id: String): Observable<any> {
    console.log("get magic content", id, this.httpOptions);
    return this.http.get<any>(AppSettings.API_ENDPOINT+'/magic/plugins/text/'+id, this.httpOptions)
      .pipe(map(res => res))
  }

  getContentForMagicPoints(mps: MagicPoint[]): Observable<any> {
    var i = 1;
    let self = this;
    let requests:Observable<MagicPoint>[] = [];
    mps.forEach(magicPoint => {
      requests.push(self.getMagicContent(magicPoint._id));
    });
    let res = combineLatest(requests);
    return res
  }

  updateMagicContent(id: String, content: String): Observable<any> {
    return this.http.post<any>(AppSettings.API_ENDPOINT+'/magic/plugins/text', JSON.stringify({_id: id, content: content}), this.httpOptions)
    .pipe(map(res => res))
  }

  deleteMagic(magic: MagicPoint): Observable<any> {
    this.nativeService.deleteMagicPoint(magic);
    return this.http.delete(AppSettings.API_ENDPOINT+'/magic/'+magic._id, this.httpOptions).pipe(map(res => res))
  }


  reorderMagicPoints(magicPoints: MagicPoint[]): Observable<any> {
    var i = 1;
    let self = this;
    let requests:Observable<MagicPoint>[] = [];
    magicPoints.forEach(magicPoint => {
      magicPoint.step = i;
      requests.push(self.saveMagic(magicPoint));
      i += 1;
    });

    let res = combineLatest(requests);
    return res
  }

  handleError(error: Response | any) {
      console.log(error);
  }
}